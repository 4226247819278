import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRememberMeAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('adminAccessToken')) {
        navigate('/admin/home');
    }
  }, [navigate]);
  
};

export default useRememberMeAdmin;
