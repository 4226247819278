// Here doc can be CPF (xxx.xxx.xxx-xx) or CNPJ (xx.xxx.xxx/xxxx-xx)

const validateDocOrEmail = (doc, setDoc, setDocWarning) => {
    const aux = doc;
    doc = doc.replaceAll('.', '');
    doc = doc.replaceAll('-', '');
    doc = doc.replaceAll('/', '');
    if (!doc.length) {
      setDocWarning('');
      setDoc('');
      return;
    }
    if (/^[0-9]+$/.test(doc)) { // recognizes as doc
        if (doc.length > 14) return;
        setDocWarning(doc.length !== 14 && doc.length !== 11 ? 'Insira um CPF/CNPJ válido' : '');
        let docPointer = 0;
        let formattedDoc = '';
        if (doc.length <= 11) { // recognizes as CPF
          while (docPointer < doc.length) {
            if ([3, 6].includes(docPointer)) formattedDoc += '.';
            if (docPointer === 9) formattedDoc += '-';
            formattedDoc += doc[docPointer];
            docPointer++;
          }
          setDoc(formattedDoc);
        } else { // recognizes as CNPJ
          while (docPointer < doc.length) {
            if ([2, 5].includes(docPointer)) formattedDoc += '.';
            if (docPointer === 8) formattedDoc += '/';
            if (docPointer === 12) formattedDoc += '-';
            formattedDoc += doc[docPointer];
            docPointer++;
          }
          setDoc(formattedDoc);
        }
    } else { // recognizes as email
        setDoc(aux);
        if (doc.length > 50) {
            setDocWarning("Insira um email menor");
            return;
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(aux)) {
            setDocWarning("Insira um email válido");
            return;
        }
        setDocWarning('');
    }
};

export default validateDocOrEmail;