const validateEmail = (email, setEmail, setEmailWarning) => {
    setEmail(email);
    if (!email.length) {
        setEmailWarning('');
        setEmail('');
        return;
    }
    if (email.length > 50) {
        setEmailWarning("Insira um email menor");
        return;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/.test(email)) {
        setEmailWarning("Insira um email válido");
        return;
    }
    setEmailWarning('');
}

export default validateEmail;