import React, { useState } from "react";
import axios from 'axios';

import NavBar from "../../layout/NavBar/NavBar";
import { useNavigate } from 'react-router-dom';

import ForgotEmailForm from "../../components/Forms/ForgotEmailForm/ForgotEmailForm";
import ForgotEmailForm2 from "../../components/Forms/ForgotEmailForm/ForgotEmailForm2";

import {
    validateDoc,
} from '../../validation/index';

import useSetPageTitle from "../../hooks/useSetPageTitle";

function ForgotEmail() {
    const navigate = useNavigate();
    useSetPageTitle('Promos Juntos+ | Esqueci meu email');

    const [doc, setDoc] = useState('');
    const [docWarning, setDocWarning] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [retrievedEmail, setRetrievedEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleDoc = (inputedDoc) => validateDoc(inputedDoc, setDoc, setDocWarning);

    const trySubmitingDoc = (e) => {
        e.preventDefault();
        if (!doc.length) setDocWarning('Este é um campo obrigatório');
        if (!doc.length || docWarning.length) {
            return;
        }
        setLoading(true);
        axios
            .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/recupera-email`, {
                doc: doc
            })
            .then((response) => {
                setLoading(false);
                sessionStorage.setItem('doc', doc);
                setRetrievedEmail(response.data.email);
                setPhoneNumber(response.data.phoneNumber);
                console.log("Email recuperado:", response.data.email);
            })
            .catch((err) => {
                setLoading(false);
                if (err.response && err.response.status === 404) {
                    setDocWarning('CPF/CNPJ não cadastrado');
                    setErrorMessage('Elegível não encontrado - 404');
                } else if (err.response && err.response.status === 401) {
                    setDocWarning('');
                    setErrorMessage('Elegível encontrado mas é bloqueado para a promoção atual - 401');
                } else {
                    setErrorMessage('Ocorreu um erro. Tente novamente mais tarde.');
                }
                console.log("Erro:", err);
            });
    };

    console.log("Estado retrievedEmail:", retrievedEmail);

    return (
        <div className="background">
            <NavBar />
            <div className="forgot-email-page-container">
                {retrievedEmail ? (
                    <ForgotEmailForm2
                        retrievedEmail={retrievedEmail}
                        nav={() => navigate('/login')}
                        loading={loading}
                        phoneNumber={phoneNumber}
                    />
                ) : (
                    <ForgotEmailForm
                        doc={doc}
                        setDoc={handleDoc}
                        docWarning={docWarning}
                        nav={() => navigate('/login')}
                        onClick={trySubmitingDoc}
                        loading={loading}
                    />
                )}
                {errorMessage && (
                    <div className="forgot-email-error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ForgotEmail;
