const validatePassword = (inputedPassword, setPassword, setPasswordWarning) => {
    setPassword(inputedPassword);
    if (!inputedPassword.length) {
        setPasswordWarning('');
        return;
    }
    if (inputedPassword.length < 6) {
        setPasswordWarning("Sua senha deve ter no mínimo 6 caracteres");
    } else setPasswordWarning('');
}

export default validatePassword;