import React, { useState } from "react";
import axios from 'axios';

import NavBar from "../../layout/NavBar/NavBar";

import { useNavigate } from 'react-router-dom';

import ForgotPasswordForm from "../../components/Forms/ForgotPasswordForm/ForgotPasswordForm";

import {
    validateEmail,
} from '../../validation/index';

import useSetPageTitle from "../../hooks/useSetPageTitle";

function ForgotPassword() {

    const navigate = useNavigate();

    useSetPageTitle('Promos Juntos+ | Esqueci minha senha');

    const [email, setEmail] = useState('');
    const [emailWarning, setEmailWarning] = useState('');

    const [loading, setLoading] = useState('');

    const handleEmail = (inputedEmail) => validateEmail(inputedEmail, setEmail, setEmailWarning);

    const trySubmitingEmail = (e) => {
        e.preventDefault();
        if (!email.length) setEmailWarning('Este é um campo obrigatório');
        if (!email.length || emailWarning.length) {
                return;
            }
        setLoading(true);
        axios
            .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/esqueci-senha`, {
                email: email
            })
            .then((response) => {
                setLoading(false);
                navigate('/login');
            })
            .catch((err) => {
                setLoading(false);
                console.log(err)
            })
    }


    return(
        <div className="background">
            <NavBar />
            <div className="forgot-password-page-container">
                <ForgotPasswordForm
                    email={email} 
                    setEmail={handleEmail}
                    emailWarning={emailWarning}
                    nav={() => navigate('/login')}
                    onClick={trySubmitingEmail}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default ForgotPassword;