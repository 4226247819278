import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import winnerMobile from '../../../assets/banner/winner-mobile.png';
import winnerDesktop from '../../../assets/banner/winner-desktop.png';

import close from '../../../assets/icons/close-grey.png';

import './styles.css';

function Popup({ setPopup }) {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);
    const isMobile = width < 768;
    const navigate = useNavigate();

    const handleClose = () => {
        localStorage.setItem('popupClosed', 'true');
        setPopup(false);
    }

    const handleClick = () => {
        localStorage.setItem('popupClosed', 'true');
        setPopup(false);
        navigate('/premios')
    }

    return(
        <div className="popup-background">
            <div className="popup-container">
                {/* <img src={close} className="popup-close" alt="close" onClick={handleClose} /> */}
                <img src={isMobile ? winnerMobile : winnerDesktop} alt="banner-week" className="popup-img" />
                <div className="popup-cta-container" onClick={handleClick}>
                    <p className="popup-cta-text">Clique aqui</p>
                </div>
            </div>
        </div>
    )
}

export default Popup;