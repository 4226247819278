import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import TextInput from '../../../components/TextInput/TextInput';
import HideTextInput from '../../../components/TextInput/HideTextInput';
import useRememberMeAdmin from '../../../hooks/admin/useRememberMeAdmin';
import useSetPageTitle from '../../../hooks/useSetPageTitle';

import {
    validateEmail,
    validatePassword,
} from '../../../validation/index';

import LinearLoader from "../../../assets/loaders/LinearLoader";

import './styles.css';
import logo from '../../../assets/images/femsa-logo.png';
import back from '../../../assets/icons/back.png';

function LoginAdmin() {

    const navigate = useNavigate();
    useRememberMeAdmin();
    useSetPageTitle('Promos Juntos+ | Admin');

    const [email, setEmail] = useState('');
    const [emailWarning, setEmailWarning] = useState('');

    const [password, setPassword] = useState('');
    const [passwordWarning, setPasswordWarning] = useState('');

    const [loading, setLoading] = useState(false);

    const handleEmail = (inputedEmail) => validateEmail(inputedEmail, setEmail, setEmailWarning);
    const handlePassword = (inputedPassword) => validatePassword(inputedPassword, setPassword, setPasswordWarning);

    const tryLogginIn = (e) => {
        e.preventDefault();
        if (!email.length) setEmailWarning('Este é um campo obrigatório');
        if (!password.length) setPasswordWarning('Este é um campo obrigatório');
        if (!email.length || emailWarning.length
            || !password.length || passwordWarning.length) {
                return;
            }
        setLoading(true);
        axios
            .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/login`, {
                user: email,
                password: password
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    localStorage.setItem('email', response.data.client.email);
                    localStorage.setItem('adminAccessToken', response.data.accessToken);
                    localStorage.setItem('role', response.data.client.role);
                    setEmailWarning('');
                    setEmailWarning('');
                    setPasswordWarning('');
                    navigate('/admin/home');
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.status === 404) {
                    setEmailWarning('Email não cadastrado');
                    setPasswordWarning('');
                    return;
                }
                if (err.response.status === 401) {
                    setEmailWarning('');
                    setPasswordWarning('Senha incorreta');
                    return;
                }
            })
    }

    return(
        <div className="admin-login-background">
            <div className="admin-login-container">
                <div className="admin-login-logo-container">
                    <img src={logo} className="admin-login-logo-image" alt="femsa-logo"/>
                </div>
                <form className="admin-login-inputs-container">
                    <TextInput
                        label="Insira seu email"
                        value={email}
                        setValue={handleEmail}
                        placeholder="Email"
                        warningMessage={emailWarning}
                        rubik
                        black
                    />
                    <HideTextInput
                        label="Insira sua senha"
                        value={password}
                        setValue={handlePassword}
                        placeholder="Senha"
                        warningMessage={passwordWarning}
                        rubik
                        black
                    />
                    <button className='admin-login-button' onClick={tryLogginIn}>
                        { loading ? <LinearLoader white /> : 'Entrar' }
                    </button>
                </form>
                <div className="admin-login-return-container" onClick={() => navigate('/')}>
                    <img src={back} className="admin-login-back-icon" alt="back-icon" />
                    <p>Ir para o portal de promoções</p>
                </div>
            </div>
        </div>
    )
}

export default LoginAdmin;