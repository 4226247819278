import React, { useState, useEffect } from "react";
import Select from 'react-select';

function SelectInput({
    options, setOption, value, rubik
}) {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);
    const isMobile = width < 768;
    return(
        <Select 
        options={options} 
        value={value}
        onChange={setOption}
        styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '1.12rem',
                width: isMobile ? '160px' : '280px',
                padding: '6px 10px',
                borderRadius: '20px',
                fontFamily: rubik ? 'Rubik' : 'Barlow Condensed',
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => (
            {
                ...styles,
                fontSize: '1rem',
                color: 'black',
                fontFamily: rubik ? 'Rubik' : 'Barlow Condensed'
            })
        }} 
        theme={(theme) => ({
            ...theme,
            borderRadius: 6,
            colors: {
            ...theme.colors,
            primary25: '#fbfbfb',
            primary: 'rgba(218, 41, 28, 0.2)',
            fontFamily: rubik ? 'Rubik' : 'Barlow Condensed'
            },
          })}/>
    )
}

export default SelectInput;