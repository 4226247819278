import React, { useState } from "react";
import axios from 'axios';

import './styles.css';

import TextInput from "../../../components/TextInput/TextInput";
import HideTextInput from "../../../components/TextInput/HideTextInput";
import RadioButtons from "../../../components/Admin/RadioButtons/RadioButtons";

import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import {
    validateEmail,
    validatePassword,
} from '../../../validation/index';

function RegisterAdmin() {
    useAuthAdmin();
    useSetPageTitle('Promos Juntos+ | Admin');
    
    const [name, setName] = useState('');
    const [nameWarning, setNameWarning] = useState('');
    
    const [email, setEmail] = useState('');
    const [emailWarning, setEmailWarning] = useState('');

    const [password, setPassword] = useState('');
    const [passwordWarning, setPasswordWarning] = useState('');

    const [option, setOption] = useState(0);
    const radioButtonsList = [
        'Nível 1: pode somente baixar dados sobre a promoção',
        'Nível 2: pode editar e baixar dados sobre a promoção',
        'Nível 3 (master): pode adicionar usuários, editar e baixar dados'
    ];

    const handleEmail = (inputedEmail) => validateEmail(inputedEmail, setEmail, setEmailWarning);
    const handlePassword = (inputedPassword) => validatePassword(inputedPassword, setPassword, setPasswordWarning);

    const tryRegistering = (e) => {
        e.preventDefault();
        axios
            .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/user/register`, {
                name: name,
                email: email,
                password: password,
                role: option + 1
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                },
            })
            .then((response) => {
                console.log(response)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return(
        <>
            <div className="admin-page-container">
            <div className="admin-page-title-container">
                <p className="admin-page-title">Registrar novo usuário</p>
            </div>
            <div className="admin-page-register-form-container">
                <TextInput
                    label="Insira o nome"
                    placeholder="Nome do usuário"
                    value={name}
                    setValue={setName}
                    warningMessage={nameWarning}
                    rubik
                    black
                />
                <TextInput
                    label="Insira o email"
                    placeholder="exemplo@email.com"
                    value={email}
                    setValue={handleEmail}
                    warningMessage={emailWarning}
                    rubik
                    black
                />
                <HideTextInput
                    label="Insira a senha"
                    placeholder="Insira pelo menos 8 caracteres"
                    value={password}
                    setValue={handlePassword}
                    warningMessage={passwordWarning}
                    rubik
                    black
                />
            </div>
            <RadioButtons 
                radioButtonsList={radioButtonsList}
                option={option}
                setOption={setOption} 
            />
            <button className="admin-page-primary-button" onClick={tryRegistering}>Adicionar usuário</button>
            </div>
        </>
    )
}

export default RegisterAdmin;