import axios from 'axios';

const downloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'cadastrados-atual');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const tryExportingRegistered = (option, setLoading, setErrorExport) => {
    setLoading(true);
    if (option === 0) {
        axios
            .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/clientes/export`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                },
            })
            .then((response) => {
                downloadCsv(response.data.url);
                setLoading(false);
            })
            .catch((err) => {
                setErrorExport(err.response.data.message);
                setLoading(false);
            });
    } else {
        axios
            .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/clientes/export?type=sem_cadastro`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                },
            })
            .then((response) => {
                downloadCsv(response.data.url);
                setLoading(false);
            })
            .catch((err) => {
                setErrorExport(err.response.data.message);
                setLoading(false);
            });
    }
}

export default tryExportingRegistered;