import { createContext } from 'react';
import { useEffect, useState } from "react";

export function useAdminContext() {
    const [promos, setPromos] = useState([]);
    const [currentPromo, setCurrentPromo] = useState(null);

    useEffect(() => {
        console.log('Promo change', currentPromo)
    }, [currentPromo])

    return { currentPromo, setCurrentPromo, promos, setPromos }
};

const AdminContext = createContext({});
export default AdminContext;
