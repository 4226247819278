import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchClientsResults = () => {
    const [pendingPrizes, setPendingPrizes] = useState(0);
    const [results, setResults] = useState([]);

    useEffect(() => {
        axios
            .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/client/meus-resultados`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                  },
            })
            .then((response) => {
                setResults(response.data.data);
                setPendingPrizes(response.data.data.filter((result) => result.status === 'aguardando').length);
                if (!localStorage.getItem('popupClosed')) {
                    localStorage.setItem('popupClosed', 'false');
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setResults]);

    return { results, pendingPrizes };
}

export default useFetchClientsResults;