import React from "react";
import { useNavigate } from "react-router-dom";

import './styles.css';

import femsaLogo from '../../assets/images/femsa-logo.svg';
import juntosLogo from '../../assets/images/juntos-logo2.png';
import instagram from '../../assets/icons/instagram.png';
import facebook from '../../assets/icons/facebook.png';

function NavBar() {

    const navigate = useNavigate();

    return(
        <div className="navbar-container">
            <div className="navbar-logo-container">
                <img src={femsaLogo} className="navbar-femsa-logo-image" alt="femsa-logo" />
                <img src={juntosLogo} className="navbar-juntos-logo-image" alt="femsa-logo" />
            </div>
            <div className="navbar-right-container">
                <div className="navbar-signin-container only-mobile" onClick={() => navigate(window.location.href.includes('signin') ? '/login' : '/signin')}>
                    <p>{window.location.href.includes('signin') ? 'Entrar' : 'Cadastre-se'}</p>
                </div>
                <div className="navbar-signin-container only-desktop" onClick={() => navigate('/signin')}>
                    <p>Cadastre-se</p>
                </div>
                <div className="navbar-login-container" onClick={() => navigate('/login')}>
                    <p>Entrar</p>
                </div>
                <div className="navbar-social-container">
                    <a className="navbar-social-icon" href="https://www.instagram.com/juntosplusbr/" target="_blank" rel="noopener noreferrer">
                    <img className="navbar-social-icon" src={instagram} alt="ig-logo" />
                    </a>
                    <a className="navbar-social-icon" href="https://www.facebook.com/JuntosPlusBR/" target="_blank" rel="noopener noreferrer">
                    <img className="navbar-social-icon" src={facebook} alt="fb-logo" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NavBar;