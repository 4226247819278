import React from 'react';

import './styles.css';

function FileInput({ label, setValue, warningMessage }) {
    return(
        <div className="file-input-container">
            <p className="file-input-label">{label}</p>
            <input type="file" className="file-input" onChange={(e) => setValue(e.target.files[0])} />
            <p className="file-input-warning">{warningMessage}</p>
        </div>
    )
}

export default FileInput;