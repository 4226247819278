import React from "react";
import './styles.css';
import TextInput from '../../TextInput/TextInput';
import TitleOn from "../../../layout/TitleOn/TitleOn";
import LinearLoader from "../../../assets/loaders/LinearLoader";
import danger from '../../../assets/icons/danger.png';  // Importe a imagem de aviso

function ForgotEmailForm({
    doc, setDoc, docWarning, onClick, nav, loading, isAlreadyRegistered
}) {
    return (
        <form className="forgot-email-container">
            <TitleOn title='Esqueci meu email' />
            <div className="forgot-email-card">
                <div className="forgot-email-form-container">
                    <p className="forgot-email-form-title">Vamos te ajudar!</p>
                    <p className="forgot-email-form-subtitle">Lembrou seu email? <span className="create-account" onClick={nav}>Volte e logue aqui</span></p>
                    {!loading && !isAlreadyRegistered && (
                        <div className="forgot-email-form-inputs-container">
                            <TextInput
                                label="Seu CPF/CNPJ cadastrado"
                                value={doc}
                                setValue={setDoc}
                                warningMessage={docWarning}
                            />
                        </div>
                    )}
                    {isAlreadyRegistered && (
                        <div className="signin-form-invalid-cpf-border">
                            <div className="signin-form-invalid-cpf-container">
                                <div className="signin-form-invalid-cpf-warning-container">
                                    <img src={danger} alt="danger" className="signin-form-invalid-cpf-warning-img" />
                                    <p className="signin-form-invalid-cpf-warning-text">Atenção!</p>
                                </div>
                                <p className="signin-form-invalid-cpf-info">Esse CPF/CNPJ ainda não possui cadastro em nosso portal de promoções. Clique aqui para fazer o seu cadastro.</p>
                                <a
                                    className="signin-form-invalid-cpf-button"
                                    href='/signin'
                                >Cadastrar</a>
                            </div>
                        </div>
                    )}
                    <button type="submit" className="forgot-email-form-button" onClick={onClick}>
                        {loading ? <LinearLoader white /> : <p>Recuperar email</p>}
                    </button>
                </div>
            </div>
        </form>
    );
}

export default ForgotEmailForm;
