import React, { useState } from 'react';
import axios from 'axios';

import './styles.css';

import edit from '../../../assets/icons/edit.png';
import close from '../../../assets/icons/close.png';

import TextInput from '../../TextInput/TextInput';

function ProfileForm({
    matricula, cpf,
    name, setName, nameWarning, setNameWarning,
    email, setEmail, emailWarning, setEmailWarning,
    phone, setPhone, phoneWarning, setPhoneWarning,
}) {
    const [editable, setEditable] = useState(false);
    const handleClose = () => {
        setName(localStorage.getItem('name'));
        setEmail(localStorage.getItem('email'));
        setPhone(localStorage.getItem('phone'));
        setEditable(false);
    }

    const saveProfile = () => {
        if (!name.length) setNameWarning('Este é um campo obrigatório');
        if (!email.length) setEmailWarning('Este é um campo obrigatório');
        if (!phone.length) setPhoneWarning('Este é um campo obrigatório');
        if (!name.length || nameWarning.length
            || !email.length || emailWarning.length
            || !phone.length || phoneWarning.length) {
            return;
        }
        axios
            .put(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/client/me`, {
                nome: name,
                email: email,
                telefone: phone,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
            .then((response) => {
                localStorage.setItem('name', name);
                localStorage.setItem('email', email);
                localStorage.setItem('phone', phone);
                setEditable(false);
            })
            .catch((err) => console.log(err));
    
    }

    return(
        <div className="profile-card">
            <div className="profile-form">
                <div className="profile-upper">
                    <p className="profile-title">Dados de cadastro</p>  
                    {
                        editable ?
                        <img src={close} alt="close-icon" className="profile-edit-icon" onClick={handleClose} />
                        :
                        <img src={edit} alt="edit-icon" className="profile-edit-icon" onClick={() => setEditable(true)} />
                    }
                </div>
                <div className="profile-form-row">
                    <TextInput label="Matrícula" disabled value={matricula} black />
                    <TextInput label="CPF ou CNPJ" disabled value={cpf} black />
                </div>
                <div className="profile-form-line" />
                <TextInput
                    label="Nome"
                    value={name}
                    setValue={setName}
                    disabled={!editable}
                    warningMessage={nameWarning}
                    black
                />
                <div className="profile-form-row">
                    <TextInput
                        label="Email"
                        value={email}
                        setValue={setEmail}
                        disabled={!editable}
                        warningMessage={emailWarning}
                        black
                    />
                    <TextInput
                        label="Telefone"
                        value={phone}
                        setValue={setPhone}
                        disabled={!editable}
                        warningMessage={phoneWarning}
                        black
                    />
                </div>
                {
                    editable ?
                    <div className="profile-form-buttons-container">
                        <div className="profile-form-save-button" onClick={saveProfile}><p>Salvar</p></div>
                        <div className="profile-form-cancel-button" onClick={handleClose}><p>Cancelar</p></div>
                    </div>
                    : false
                }
            </div>
        </div>
    )
}

export default ProfileForm;