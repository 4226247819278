import React, { useState } from "react";
import axios from 'axios';

import NavBar from "../../layout/NavBar/NavBar";
import { useNavigate } from 'react-router-dom';

import NewEmailForm from "../../components/Forms/NewEmailForm/NewEmailForm";
import NewEmailForm2 from "../../components/Forms/NewEmailForm/NewEmailForm2";

import {
    validateEmail,
} from '../../validation/index';

import useSetPageTitle from "../../hooks/useSetPageTitle";

function NewEmail() {
    const navigate = useNavigate();
    useSetPageTitle('Promos Juntos+ | Novo Email');

    const [email, setEmail] = useState('');
    const [emailWarning, setEmailWarning] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [confirmEmailWarning, setConfirmEmailWarning] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [doc] = useState(sessionStorage.getItem('doc') || '');
    const [emailUpdated, setEmailUpdated] = useState(false);
    const [updatedEmail, setUpdatedEmail] = useState('');
    const [alreadyRequested, setAlreadyRequested] = useState(false);

    const handleEmail = (inputedEmail) => validateEmail(inputedEmail, setEmail, setEmailWarning);
    const handleConfirmEmail = (inputedEmail) => {
        if (inputedEmail !== email) {
            setConfirmEmailWarning('Os emails não são iguais');
        } else {
            setConfirmEmailWarning('');
        }
        setConfirmEmail(inputedEmail);
    }

    const trySavingEmail = (e) => {
        e.preventDefault();
        setErrorMessage(''); // Limpar mensagem de erro
        setAlreadyRequested(false); // Limpar estado de já solicitado
        if (!email.length) setEmailWarning('Este é um campo obrigatório');
        if (!confirmEmail.length) setConfirmEmailWarning('Este é um campo obrigatório');
        if (!email.length || emailWarning.length || !confirmEmail.length || confirmEmailWarning.length) {
            return;
        }
        setLoading(true);
        axios
            .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/alterar-email`, {
                doc: doc,
                email: email
            })
            .then((response) => {
                setLoading(false);
                setEmailUpdated(true);
                setUpdatedEmail(email);
                console.log("Email atualizado:", response.data.email);
            })
            .catch((err) => {
                setLoading(false);
                if (err.response && err.response.status === 400) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 400,
                        error_message: 'Usuário já fez solicitação de troca de e-mail - 401'
                    });
                    setAlreadyRequested(true);
                    setEmailUpdated(true);
                } else if (err.response && err.response.status === 401) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 401,
                        error_message: 'O novo e-mail já está sendo utilizado - 401'
                    });
                    setErrorMessage('O novo e-mail já está sendo utilizado');
                } else {
                    setErrorMessage('Ocorreu um erro. Tente novamente mais tarde.');
                }
                console.log("Erro:", err);
            });
    };

    console.log("Estado emailUpdated:", emailUpdated);

    return (
        <div className="background">
            <NavBar />
            <div className="new-email-page-container">
                {emailUpdated ? (
                    <NewEmailForm2
                        updatedEmail={updatedEmail}
                        nav={() => navigate('/login')}
                        alreadyRequested={alreadyRequested}
                    />
                ) : (
                    <NewEmailForm
                        email={email}
                        setEmail={handleEmail}
                        emailWarning={emailWarning}
                        confirmEmail={confirmEmail}
                        setConfirmEmail={handleConfirmEmail}
                        confirmEmailWarning={confirmEmailWarning}
                        nav={() => navigate('/login')}
                        onClick={trySavingEmail}
                        loading={loading}
                        errorMessage={errorMessage}
                    />
                )}
                {errorMessage && !emailUpdated && (
                    <div className="new-email-error-message custom-error-class">
                        <p>{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewEmail;
