import React from 'react';

import './styles.css';

function FileInput({ setValue }) {
    return(
        <div className="file-input-container-admin">
            <input type="file" accept=".csv" onChange={(e) => setValue(e.target.files[0])} />
        </div>
    )
}

export default FileInput;