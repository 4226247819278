import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRememberMe = () => {
  const navigate = useNavigate();

  useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            navigate('/home');
        }
    }, [navigate]);

};

export default useRememberMe;
