import React from 'react';

import './styles.css';

function RadioButtons({ radioButtonsList, option, setOption }) {
    return(
        <div className="radio-buttons-container">
            {
                radioButtonsList.map((radio, idx) => (
                   <input 
                    key={idx}
                    type="radio" 
                    name="radio-button" 
                    id={`radio-${idx + 1}`}
                    checked={option === idx}
                    onChange={(e) => setOption(idx)}
                    /> 
                ))
            }
            {
                radioButtonsList.map((radio, idx) => (
                    <label key={idx} htmlFor={`radio-${idx + 1}`} className={`box ${option === idx ? "radio-checked" : ""}`}>
                        <div className="plan">
                            <span className={`${option === idx ? "circle-checked" : "circle"}`}></span>
                            <span className="yearly">{radio}</span>
                        </div>
                    </label>
                ))
            }
        </div>
    )
}

export default RadioButtons;