import { useEffect } from 'react';

const useCheckRoleSideBar = (setLinks) => {

    useEffect(() => {
        if (localStorage.getItem('role') !== '3') {
            setLinks(prev => prev.slice(0, 7));
        }
    }, [setLinks]);

};

export default useCheckRoleSideBar;
