// Here doc can be CPF (xxx.xxx.xxx-xx) or CNPJ (xx.xxx.xxx/xxxx-xx)

const validateDoc = (doc, setDoc, setDocWarning) => {
    doc = doc.replaceAll('.', '');
    doc = doc.replaceAll('-', '');
    doc = doc.replaceAll('/', '');
    if (!doc.length) {
      setDocWarning('');
      setDoc('');
      return;
    }
    if (doc.length > 14) return;
    if (!/^[0-9]+$/.test(doc)) return;
    setDocWarning(doc.length !== 14 && doc.length !== 11 ? 'Insira um CPF/CNPJ válido' : '');
    let docPointer = 0;
    let formattedDoc = '';
    if (doc.length <= 11) { // recognizes as CPF
      while (docPointer < doc.length) {
        if ([3, 6].includes(docPointer)) formattedDoc += '.';
        if (docPointer === 9) formattedDoc += '-';
        formattedDoc += doc[docPointer];
        docPointer++;
      }
      setDoc(formattedDoc);
    } else { // recognizes as CNPJ
      while (docPointer < doc.length) {
        if ([2, 5].includes(docPointer)) formattedDoc += '.';
        if (docPointer === 8) formattedDoc += '/';
        if (docPointer === 12) formattedDoc += '-';
        formattedDoc += doc[docPointer];
        docPointer++;
      }
      setDoc(formattedDoc);
    }
};

export default validateDoc;