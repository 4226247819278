import React, { useState, useEffect } from "react";

import TopBar from "../../layout/TopBar/TopBar";
import './styles.css';

import SearchBar from "../../components/TextInput/SearchBar";
import PromoCard from "../../components/PromoCard/PromoCard";
import BuyNow from "../../components/Home/BuyNow/BuyNow";

import useAuthClient from '../../hooks/useAuthClient';
import useSetPageTitle from "../../hooks/useSetPageTitle";

import promosBack from "./backend/promos";

function Promos() {

    useAuthClient();
    useSetPageTitle('Promos Juntos+ | Promos');

    const [promos, setPromos] = useState(promosBack);
    const [searchPromo, setSearchPromo] = useState('');

    useEffect(() => {
        const updatedPromos = promosBack.map(promo => {
            const promoAtiva = localStorage.getItem('PromoAtiva') === 'true';
            if (promo.title === 'Toby vai de there') {
                return {
                    ...promo,
                    description: promoAtiva
                        ? 'PARABÉNS! Agora, é só fazer uma compra de Therezópolis pelo app, WhatsApp ou vendedor para ativar o seu cadastro e começar a acumular números da sorte!'
                        : 'A promoção começou! Clique no botão abaixo para participar!',
                    ended: !localStorage.getItem('ElegivelPromo'),
                    joined: promoAtiva,
                };
            }
            return promo;
        });
        setPromos(updatedPromos);
    }, []);

    const vaiDeThere = promos.find(promo => promo.title === 'Toby vai de there');
    const otherPromos = promos.filter(promo => promo.title !== 'Toby vai de there');

    return (
        <div className="background">
            <TopBar />
            <div className="promos-background">
                <SearchBar placeholder="Procure uma promoção" value={searchPromo} setValue={setSearchPromo} />
                
                {vaiDeThere && (
                    <div className="vai-de-there-premiada-container">
                        <PromoCard
                            key={vaiDeThere.title}
                            title={vaiDeThere.title}
                            description={vaiDeThere.description}
                            image={vaiDeThere.image}
                            ended={vaiDeThere.ended}
                            joined={vaiDeThere.joined}
                            newPromo={vaiDeThere.new}
                        />
                    </div>
                )}

                <div className="promos-container">
                    {
                        otherPromos
                        .filter((promo) => promo.title.toLowerCase().includes(searchPromo.toLowerCase()))
                        .map((promo) => (
                            <PromoCard
                                key={promo.title}
                                title={promo.title}
                                description={promo.description}
                                image={promo.image}
                                ended={promo.ended}
                                joined={promo.joined}
                                newPromo={promo.new}
                            />
                        ))
                    }
                </div>
                <BuyNow />
            </div>
        </div>
    )
}

export default Promos;
