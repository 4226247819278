import React from "react";

import './styles.css';

import HideTextInput from '../../TextInput/HideTextInput';
import TitleOn from "../../../layout/TitleOn/TitleOn";

import LinearLoader from "../../../assets/loaders/LinearLoader";

function ResetPasswordForm({
    password, setPassword, passwordWarning,
    confirmPassword, setConfirmPassword, confirmPasswordWarning,
    onClick, nav, loading
}) {

    return(
        <form className="forgot-password-container">
                <TitleOn title='Redefinir senha' />
                <div className="forgot-password-card">
                    <div className="forgot-password-form-container">
                        <p className="forgot-password-form-title">Nova senha</p>
                        <p className="forgot-password-form-subtitle">Qualquer dúvida, fale com nosso <span className="create-account" onClick={nav}>suporte</span></p>
                            <div className="forgot-password-form-inputs-container">
                                <HideTextInput
                                    label="Nova senha"
                                    value={password}
                                    setValue={setPassword}
                                    warningMessage={passwordWarning}
                                />
                                <HideTextInput
                                    label="Confirme sua nova senha"
                                    value={confirmPassword}
                                    setValue={setConfirmPassword}
                                    warningMessage={confirmPasswordWarning}
                                />
                            </div>
                            <button type="submit" className="forgot-password-form-button" onClick={onClick}>
                            {
                                loading ? <LinearLoader white /> : <p>Confirmar</p>
                            }
                            </button>
                    </div>
            </div>
        </form>
    )
}   

export default ResetPasswordForm;