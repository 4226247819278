import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import NavBar from "../../layout/NavBar/NavBar";
import SigninForm from "../../components/Forms/SigninForm/SigninForm";

import {
    validateDoc,
    validateName,
    validateEmail,
    validatePhone,
    validatePassword,
    validateConfirmPassword,
} from '../../validation/index';

import useRememberMe from "../../hooks/useRememberMe";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import './styles.css';

function Signin() {
    const navigate = useNavigate();

    useRememberMe();
    useSetPageTitle('Promos Juntos+ | Cadastro');

    const docRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();

    const [doc, setDoc] = useState('');
    const [docWarning, setDocWarning] = useState('');

    const [name, setName] = useState('');
    const [nameWarning, setNameWarning] = useState('');

    const [email, setEmail] = useState('');
    const [emailWarning, setEmailWarning] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneWarning, setPhoneWarning] = useState('');

    const [password, setPassword] = useState('');
    const [passwordWarning, setPasswordWarning] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordWarning, setConfirmPasswordWarning] = useState('');

    const [invalidDoc, setInvalidDoc] = useState(false);
    const [alreadySigned, setAlreadySigned] = useState(false);

    const [agreeWithRegulation, setAgreeWithRegulation] = useState(false);
    const [agreeWithRegulationWarning, setAgreeWithRegulationWarning] = useState('');
    const [agreeWithSharing, setAgreeWithSharing] = useState(false);
    const [agreeWithSharingWarning, setAgreeWithSharingWarning] = useState('');

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // Adicione o estado da mensagem de erro

    const scrollToTop = () => window.scrollTo({ top: 120, behavior: 'smooth' });
    const scrollToDoc = () => window.scrollTo({ behavior: 'smooth', top: docRef.current.offsetTop + 120 });
    const scrollToName = () => window.scrollTo({ behavior: 'smooth', top: nameRef.current.offsetTop + 120 });
    const scrollToEmail = () => window.scrollTo({ behavior: 'smooth', top: emailRef.current.offsetTop + 120 });
    const scrollToPhone = () => window.scrollTo({ behavior: 'smooth', top: phoneRef.current.offsetTop + 120 });
    const scrollToPassword = () => window.scrollTo({ behavior: 'smooth', top: passwordRef.current.offsetTop + 120 });
    const scrollToConfirmPassword = () => window.scrollTo({ behavior: 'smooth', top: confirmPasswordRef.current.offsetTop + 120 });

    const handleDoc = (inputedDoc) => validateDoc(inputedDoc, setDoc, setDocWarning);
    const handleName = (inputedName) => validateName(inputedName, setName, setNameWarning);
    const handleEmail = (inputedEmail) => validateEmail(inputedEmail, setEmail, setEmailWarning);
    const handlePassword = (inputedPassword) => validatePassword(inputedPassword, setPassword, setPasswordWarning);
    const handlePhone = (inputedPhone) => validatePhone(inputedPhone, setPhone, setPhoneWarning);
    const handleConfirmPassword = (inputedPassword) => validateConfirmPassword(inputedPassword, setConfirmPassword, setConfirmPasswordWarning, password);

    const trySigninIn = (e) => {
        e.preventDefault();
        if (!doc.length) setDocWarning('Este é um campo obrigatório');
        if (!name.length) setNameWarning('Este é um campo obrigatório');
        if (!email.length) setEmailWarning('Este é um campo obrigatório');
        if (!phone.length) setPhoneWarning('Este é um campo obrigatório');
        if (!password.length) setPasswordWarning('Este é um campo obrigatório');
        if (!confirmPassword.length) setConfirmPasswordWarning('Este é um campo obrigatório');
        if (!agreeWithRegulation) setAgreeWithRegulationWarning('Você precisa aceitar o regulamento');
        if (!agreeWithSharing) setAgreeWithSharingWarning('Você precisa aceitar este item');
        if (!doc.length || docWarning.length) {
            scrollToDoc();
            return;
        }
        if (!name.length || nameWarning.length) {
            scrollToName();
            return;
        }
        if (!email.length || emailWarning.length) {
            scrollToEmail();
            return;
        }
        if (!phone.length || phoneWarning.length) {
            scrollToPhone();
            return;
        }
        if (!password.length || passwordWarning.length) {
            scrollToPassword();
            return;
        }
        if (!confirmPassword.length || confirmPasswordWarning.length) {
            scrollToConfirmPassword();
            return;
        }
        if (!agreeWithRegulation || !agreeWithSharing) {
            return;
        }
        setLoading(true);
        axios
            .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/client/register`, {
                doc: doc,
                email: email,
                password: password,
                nome: name,
                telefone: phone,
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setInvalidDoc(false);
                    setAlreadySigned(false);
                    localStorage.setItem('doc', response.data.client.doc);
                    localStorage.setItem('id', response.data.client.id);
                    localStorage.setItem('accessToken', response.data.accessToken);
                    localStorage.setItem('name', response.data.client.nome);
                    localStorage.setItem('phone', response.data.client.telefone);
                    localStorage.setItem('email', response.data.client.email);
                    localStorage.setItem('ElegivelPromo', response.data.client.ElegivelPromo);
                    localStorage.setItem('PromoAtiva', response.data.client.PromoAtiva);
                    localStorage.setItem('firstTime', 'true');
                    setErrorMessage(''); // Limpar mensagem de erro ao se registrar com sucesso
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'cadastro-ok',
                    });
                    navigate('/home?success=true');
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.status === 404) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 404,
                        error_message: 'Elegível não encontrado - 404'
                    });
                    setAlreadySigned(false);
                    setInvalidDoc(true);
                    setErrorMessage('Elegível não encontrado - 404');
                    scrollToTop();
                    return;
                }
                if (err.response.status === 400) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 400,
                        error_message: 'Elegível já possui cadastro - 400'
                    });
                    setAlreadySigned(true);
                    setInvalidDoc(false);
                    setErrorMessage('Elegível já possui cadastro - 400');
                    scrollToTop();
                    return;
                }
                if (err.response.status === 401) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 401,
                        error_message: 'Elegível encontrado mas é bloqueado para a promoção atual - 401'
                    });
                    setErrorMessage('Elegível encontrado mas é bloqueado para a promoção atual - 401');
                    scrollToTop();
                    return;
                }
                setErrorMessage('Ocorreu um erro. Tente novamente mais tarde.');
            });
    }

    return (
        <div>
            <NavBar />
            <div className="signin-page-container">
                <SigninForm
                    doc={doc}
                    setDoc={handleDoc}
                    docWarning={docWarning}
                    docRef={docRef}
                    name={name}
                    setName={handleName}
                    nameWarning={nameWarning}
                    nameRef={nameRef}
                    email={email}
                    setEmail={handleEmail}
                    emailWarning={emailWarning}
                    emailRef={emailRef}
                    phone={phone}
                    setPhone={handlePhone}
                    phoneWarning={phoneWarning}
                    phoneRef={phoneRef}
                    password={password}
                    setPassword={handlePassword}
                    passwordWarning={passwordWarning}
                    passwordRef={passwordRef}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={handleConfirmPassword}
                    confirmPasswordWarning={confirmPasswordWarning}
                    confirmPasswordRef={confirmPasswordRef}
                    onClick={trySigninIn}
                    invalidDoc={invalidDoc}
                    alreadySigned={alreadySigned}
                    agreeWithRegulation={agreeWithRegulation}
                    setAgreeWithRegulation={setAgreeWithRegulation}
                    agreeWithRegulationWarning={agreeWithRegulationWarning}
                    setAgreeWithRegulationWarning={setAgreeWithRegulationWarning}
                    agreeWithSharing={agreeWithSharing}
                    setAgreeWithSharing={setAgreeWithSharing}
                    agreeWithSharingWarning={agreeWithSharingWarning}
                    setAgreeWithSharingWarning={setAgreeWithSharingWarning}
                    haveAnAccount={() => navigate('/login')}
                    loading={loading}
                />
                {errorMessage && (
                    <div className="signin-form-error-border">
                        <div className="signin-form-error-container">
                            <p className="signin-form-error-text">{errorMessage}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Signin;
