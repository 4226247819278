import React, { useState } from 'react';
import './styles.css';
import axios from 'axios';

function PromoCard({
  title,
  image,
  description,
  ended,
  joined: initialJoined,
  newPromo,
}) {
  const promoAtiva = localStorage.getItem('PromoAtiva') === 'true';
  const joinedInitial = initialJoined || promoAtiva;
  const [joined, setJoined] = useState(joinedInitial);
  const [errorMessage, setErrorMessage] = useState('');

  const handleButtonClick = async () => {
    console.log('Button clicked');
    if (!ended) {
      if (!joined) {
        try {
          const data = { promo_id: 3 };
          axios
            .post(
              `https://${
                window.location.host !== 'toby.promo' ? 'dev-api' : 'api'
              }.toby.promo/api/v1/client/aplicar-promo`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    'accessToken'
                  )}`,
                },
              }
            )
            .then((response) => {
              setJoined(true);
              localStorage.setItem('PromoAtiva', 'true');
              setErrorMessage('');
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'participar-promo',
              });
              window.location.reload();
            })
            .catch((err) => {
              if (err.response.status === 401) {
                setErrorMessage(
                  'Cliente não é elegível para a promoção atual.'
                );
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'error_event',
                  error_code: 401,
                  error_message:
                    'Cliente não é elegível para a promoção atual. - 401',
                });
              } else if (err.response.status === 400) {
                setErrorMessage('Você já está participando desta promoção.');
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'error_event',
                  error_code: 400,
                  error_message:
                    'Você já está participando desta promoção. - 400',
                });
                localStorage.setItem('PromoAtiva', 'true');
                window.location.reload();
              } else {
                console.log(err);
              }
            });
        } catch (error) {
          console.error('Error checking promotion status', error);
        }
      }
    }
  };

  return (
    <div
      className={`promo-card-container ${ended ? 'promo-card-disabled' : ''} ${
        joined ? 'promo-card-joined' : ''
      } ${!ended && !joined ? 'promo-card-join' : ''}`}
    >
      {newPromo && !joined && !ended ? (
        <div className='promo-card-new-alert'>NOVA</div>
      ) : (
        false
      )}
      <p className='promo-card-title notranslate'>{title}</p>
      <div className='promo-card-image-container'>
        <img alt='promo-kv' className='promo-card-image' src={image} />
      </div>
      <p className='promo-card-description'>{description}</p>
      {errorMessage && <p className='promo-card-error'>{errorMessage}</p>}
      <button
        className={`promo-card-button ${ended ? 'ended' : ''} ${
          !ended && joined ? 'joined' : ''
        } ${!ended && !joined ? 'join' : ''}`}
        onClick={handleButtonClick}
      >
        {ended ? 'FINALIZADO' : false}
        {!ended && joined ? 'PARTICIPANDO' : ''}
        {!ended && !joined ? 'PARTICIPAR' : ''}
      </button>
    </div>
  );
}

export default PromoCard;
