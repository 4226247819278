import React from "react";
import { useNavigate } from "react-router-dom";
import './styles.css';
import TitleOn from "../../../layout/TitleOn/TitleOn";
import LinearLoader from "../../../assets/loaders/LinearLoader";

function ForgotEmailForm2({
    retrievedEmail, nav, loading, phoneNumber
}) {
    const navigate = useNavigate();

    const handleNavigateToPhoneNumberConfirmation = () => {
        navigate('/confirmar-telefone');
    }

    return (
        <form className="forgot-email-container">
            <TitleOn title='Esqueci meu email' />
            <div className="forgot-email-card">
                <div className="forgot-email-form-container">
                    <p className="forgot-email-form-title">Vamos te ajudar!</p>
                    <p className="forgot-email-form-subtitle">Lembrou seu email? <span className="create-account" onClick={nav}>Volte e logue aqui</span></p>
                    <p className="forgot-email-form-subtitle response-email">Seu email é: {retrievedEmail}</p>
                    <p className="forgot-email-form-text">Não reconhece esse e-mail?</p>
                    <button type="button" className="forgot-email-form-button" onClick={handleNavigateToPhoneNumberConfirmation}>
                        {loading ? <LinearLoader white /> : <p>Confirmar meu telefone</p>}
                    </button>
                </div>
            </div>
        </form>
    );
}

export default ForgotEmailForm2;
