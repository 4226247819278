import React from "react";
import './styles.css';
import TitleOn from "../../../layout/TitleOn/TitleOn";

function NewEmailForm2({
    updatedEmail, nav, alreadyRequested
}) {
    return (
        <form className="new-email-container">
            <TitleOn title='Novo Email' />
            <div className="new-email-card">
                <div className="new-email-form-container">
                    {alreadyRequested ? (
                        <>
                            <p className="new-email-form-subtitle" id="text-email">Você já efetuou essa solicitação</p>
                            <p className="new-email-form-subtitle">Nossos especialistas irão avaliar sua solicitação em até 24h você receberá a confirmação em seu novo e-mail.</p>
                            <button onClick={nav} className="new-email-form-button">Voltar</button>
                        </>
                    ) : (
                        <>
                            <br></br>
                            <p className="new-email-form-subtitle" id="text-email">Solicitação de troca de e-mail realizada com sucesso!</p>
                            <p className="new-email-form-subtitle">Nossos especialistas irão avaliar sua solicitação em até 24h você receberá a confirmação em seu novo e-mail: <span className="color-mail">{updatedEmail}</span></p>
                            <p className="back-login"> <span onClick={nav}>Volte para o login</span></p>
                        </>
                    )}
                </div>
            </div>
        </form>
    );
}

export default NewEmailForm2;
