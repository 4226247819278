import React, { useState, useEffect } from 'react';

import './styles.css';

import logo from '../../../assets/images/toby-there-logo-white.png';
import instagram from '../../../assets/icons/instagram.png';
import facebook from '../../../assets/icons/facebook.png';

function Footer() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);
  const isMobile = width < 768;

  if (isMobile) {
    return (
      <div className='footer-container-mobile'>
        <div className='footer-links-mobile'>
          <a
            href='https://tobyvaidethere.com/faq/'
            target='_blank'
            rel='noreferr noreferrer'
          >
            FAQ
          </a>
          <a
            href='https://tobyvaidethere.com/regulamento'
            target='_blank'
            rel='noreferr noreferrer'
          >
            Regulamento
          </a>
        </div>
        <div className='footer-burocracy-mobile'>
          <p>
            Imagens meramente ilustrativas. Prêmios semanais (vales-presente)
            entregues em cartões de crédito virtuais, bloqueados para saque.
            Grande prêmio (automóvel) entregue em certificado de barras de ouro.
            Saiba mais acessando o regulamento da promoção. Promoção autorizada
            pela SPA/MF. Promoção válida de 01.07.2024 a 17.10.2024 ou enquanto
            durarem os estoques. Promoção para maiores de 18 (dezoito) anos,
            válida nos estados de SP, RJ, MG, MS, PR, RS e SC. Produtos
            participantes: portfólio de Therezópolis da Coca-Cola FEMSA, em todas as suas apresentações.
            Prêmios semanais (vales-presente) entregues em cartões de crédito
            virtuais, bloqueados para saque. Grande prêmio (automóvel) entregue
            em certificado de barras de ouro. Consulte cidades participantes,
            descrição, valor dos prêmios e condições de participação, acessando
            o regulamento da promoção, disponível em tobyvaidethere.com.
          </p>
        </div>
        <div className='footer-logo-container-mobile'>
          <img
            alt='logo'
            className='footer-logo-mobile'
            src={logo}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
        </div>
        <div className='footer-social-mobile'>
          <a
            href='https://www.instagram.com/juntosplusbr/'
            target='_blank'
            rel='noreferr noreferrer'
          >
            <img src={instagram} alt='ig' />
          </a>
          <a
            href='https://www.facebook.com/JuntosPlusBR/'
            target='_blank'
            rel='noreferr noreferrer'
          >
            <img src={facebook} alt='fb' />
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <div className='footer-container'>
        <div className='footer-up'>
          <div className='footer-links'>
            <a
              href='https://tobyvaidethere.com/faq/'
              target='_blank'
              rel='noreferr noreferrer'
            >
              FAQ
            </a>
            <a
              href='https://tobyvaidethere.com/regulamento'
              target='_blank'
              rel='noreferr noreferrer'
            >
              Regulamento
            </a>
          </div>
          <div className='footer-social'>
            <a
              href='https://www.instagram.com/juntosplusbr/'
              target='_blank'
              rel='noreferr noreferrer'
            >
              <img src={instagram} alt='ig' />
            </a>
            <a
              href='https://www.facebook.com/JuntosPlusBR/'
              target='_blank'
              rel='noreferr noreferrer'
            >
              <img src={facebook} alt='fb' />
            </a>
          </div>
          <div className='footer-logo-container'>
            <img
              alt='logo'
              className='footer-logo'
              src={logo}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            />
          </div>
        </div>
        <div className='footer-burocracy'>
          <p>
            Imagens meramente ilustrativas. Prêmios semanais (vales-presente)
            entregues em cartões de crédito virtuais, bloqueados para saque.
            Grande prêmio (automóvel) entregue em certificado de barras de ouro.
            Saiba mais acessando o regulamento da promoção. Promoção autorizada
            pela SPA/MF. Promoção válida de 01.07.2024 a 17.10.2024 ou enquanto
            durarem os estoques. Promoção para maiores de 18 (dezoito) anos,
            válida nos estados de SP, RJ, MG, MS, PR, RS e SC. Produtos
            participantes: portfólio de Therezópolis da Coca-Cola FEMSA, em todas as suas apresentações.
            Prêmios semanais (vales-presente) entregues em cartões de crédito
            virtuais, bloqueados para saque. Grande prêmio (automóvel) entregue
            em certificado de barras de ouro. Consulte cidades participantes,
            descrição, valor dos prêmios e condições de participação, acessando
            o regulamento da promoção, disponível em tobyvaidethere.com.
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
