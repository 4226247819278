import React from 'react';

import './styles.css';
import close from '../../../assets/icons/close-grey.png';

import TextInput from '../../TextInput/TextInput';
import FileInput from '../../FileInput/FileInput';

import {
    validatePhone,
    validateDefault,
} from '../../../validation/index';

function PrizeForm({ 
    info, setVisibleForm,
    cep, setCep, cepWarning, setCepWarning,
    street, setStreet, streetWarning, setStreetWarning,
    number, setNumber, numberWarning, setNumberWarning,
    complement, setComplement,
    neighborhood, setNeighborhood, neighborhoodWarning, setNeighborhoodWarning,
    state, setState, stateWarning, setStateWarning,
    city, setCity, cityWarning, setCityWarning,
    country, setCountry, countryWarning, setCountryWarning,
    phone, setPhone, phoneWarning, setPhoneWarning,
    setFileIdt, fileIdtWarning, setFileIdtWarning,
    setFileSocialContract, fileSocialContractWarning, setFileSocialContractWarning,
    setFileReceipt, fileReceiptWarning, setFileReceiptWarning,
    onClick, clearFields,
}) {
    const searchCep = () => {
        let myCep = cep.replace('-', '');
        if (myCep.length !== 8) return;
        fetch(`http://viacep.com.br/ws/${myCep}/json/`, { mode: 'cors' })
        .then((res) => res.json())
        .then((data) => {
            if (data.hasOwnProperty("erro")) {
                setCepWarning("CEP não existente");
            } else {
                setCepWarning('');
                setStreet(data.logradouro);
                setStreetWarning('');
                setNeighborhood(data.bairro);
                setNeighborhoodWarning('');
                setCity(data.localidade);
                setCityWarning('');
                setState(data.uf);
                setStateWarning('');
                setCountry('Brasil');
                setCountryWarning('');
            }
        })
        .catch(err => console.log(err));
    }

    const handlePhone = (inputedPhone) => validatePhone(inputedPhone, setPhone, setPhoneWarning);
    const handleStreet = (inputedStreet) => validateDefault(inputedStreet, setStreet, setStreetWarning);
    const handleNumber = (inputedNumber) => validateDefault(inputedNumber, setNumber, setNumberWarning);
    const handleNeighborhood = (inputedNeighborhood) => validateDefault(inputedNeighborhood, setNeighborhood, setNeighborhoodWarning);
    const handleCity = (inputedCity) => validateDefault(inputedCity, setCity, setCityWarning);
    const handleState = (inputedState) => validateDefault(inputedState, setState, setStateWarning);
    const handleCountry = (inputedCountry) => validateDefault(inputedCountry, setCountry, setCountryWarning);

        return(
            <div className="prize-form-background">
                <div className="prize-form-container">
                    <img className="prize-form-close" src={close} alt="close-icon" onClick={() => {
                        setVisibleForm(false);
                        clearFields();
                    }} />
                    <p className="prize-form-title">Resgate seu prêmio</p>
                    <p className="prize-form-subtitle">{info.prizeName}</p>
                    <div className="address-form">
                        <div className="address-form-cep-container">
                            <TextInput
                                label="CEP*"
                                value={cep}
                                setValue={setCep}
                                warningMessage={cepWarning}
                                black
                            />
                            <p className="address-form-search-cep" style={{
                                opacity: cep.length === 9 ? 1 : 0.5,
                                cursor: cep.length === 9 ? 'pointer' : 'default'
                            }} onClick={searchCep}>Buscar</p>
                        </div>
                        <div className="address-form-row">
                            <TextInput
                                label="Rua*"
                                value={street}
                                setValue={handleStreet}
                                warningMessage={streetWarning}
                                black
                            />
                            <div style={{ flexGrow: '3' }}>
                                <TextInput
                                    label="Número*"
                                    value={number}
                                    setValue={handleNumber}
                                    warningMessage={numberWarning}
                                    black
                                />
                            </div>
                        </div>
                        <div className="address-form-input-container-100">
                            <TextInput
                                label="Complemento"
                                value={complement}
                                setValue={setComplement}
                                black
                            />
                        </div>
                        <div className="address-form-row">
                            <TextInput label="Bairro*" value={neighborhood} setValue={handleNeighborhood} warningMessage={neighborhoodWarning} black />
                            <TextInput label="Estado*" value={state} setValue={handleState} warningMessage={stateWarning} black />
                        </div>
                        <div className="address-form-row">
                            <TextInput label="Cidade*" value={city} setValue={handleCity} warningMessage={cityWarning} black />
                            <TextInput label="País*" value={country} setValue={handleCountry} warningMessage={countryWarning} black />
                        </div>
                    </div>
                    <div className="prize-form-separator" />
                    <br></br>
                    <div className="address-form">
                        <div className="address-form-input-container-100">
                            <TextInput
                                label="Telefone para contato*"
                                value={phone}
                                setValue={handlePhone}
                                warningMessage={phoneWarning}
                                black
                            />
                        </div>
                    </div>
                        <div className="prize-form-separator" />
                    <div className="files-form">
                        <div className="files-form-title-container">
                            <p className="files-form-title">Upload de documentos</p>
                        </div>
                        <div className="file-input-container">
                            <FileInput 
                                label='Recibo do prêmio preenchido*'
                                setValue={(file) => {
                                    setFileReceipt(file);
                                    setFileReceiptWarning('');
                                }}
                                warningMessage={fileReceiptWarning}
                            />
                        </div>
                        <div className="file-input-container">
                            <FileInput
                                label='Documento de identidade (RG/CNH)*'
                                setValue={(file) => {
                                    setFileIdt(file);
                                    setFileIdtWarning('');
                                }}
                                warningMessage={fileIdtWarning}
                            />
                        </div>
                        {
                            info.prizeId === 7 ?
                            <div className="file-input-container">
                                <FileInput
                                    label='Contrato Social'
                                    setValue={(file) => {
                                        setFileSocialContract(file);
                                        setFileSocialContractWarning('');
                                    }}
                                    warningMessage={fileSocialContractWarning}
                                />
                            </div>
                            : false
                        }
                    </div>
                    <button className="prize-form-submit-button" onClick={onClick}>Confirmar</button>
                </div>
            </div>
        )
}

export default PrizeForm;