import React from "react";
import './styles.css';
import TextInput from '../../TextInput/TextInput';
import TitleOn from "../../../layout/TitleOn/TitleOn";
import LinearLoader from "../../../assets/loaders/LinearLoader";

function NewEmailForm({
    email, setEmail, emailWarning,
    confirmEmail, setConfirmEmail, confirmEmailWarning,
    onClick, nav, loading, errorMessage
}) {
    return (
        <form className="new-email-container">
            <TitleOn title='Novo Email' />
            <div className="new-email-card">
                <div className="new-email-form-container">
                    <p className="new-email-form-title">Configure um novo email!</p>
                    <br></br>
                    <div className="new-email-form-inputs-container">
                        <TextInput
                            label="Seu novo email:"
                            value={email}
                            setValue={setEmail}
                            warningMessage={emailWarning}
                        />
                        <TextInput
                            label="Confirme seu novo email:"
                            value={confirmEmail}
                            setValue={setConfirmEmail}
                            warningMessage={confirmEmailWarning}
                        />
                    </div>
                    {errorMessage && (
                        <div className="new-email-error-message custom-error-class">
                            <p>{errorMessage}</p>
                        </div>
                    )}
                    <button type="submit" className="new-email-form-button" onClick={onClick}>
                        {loading ? <LinearLoader white /> : <p>Salvar email</p>}
                    </button>
                </div>
            </div>
        </form>
    );
}

export default NewEmailForm;
