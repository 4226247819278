import axios from 'axios';

const downloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'elegiveis-atual');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const tryExportingElegibles = (setLoading, setErrorExport) => {
    setLoading(true);
    axios
        .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/elegiveis/export`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
            },
        })
        .then((response) => {
            downloadCsv(response.data.url);
            setLoading(false);
        })
        .catch((err) => {
            setLoading(false);
            setErrorExport(err.response.data.message)
        });
}

export default tryExportingElegibles;