import React, { useState, useEffect } from 'react';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './styles.css';

import prizeDesktop from '../../../assets/prizes/prize-desktop.png';
import prize from '../../../assets/prizes/prize-mobile.png';
import premiosDesktop from '../../../assets/prizes/premios-desktop.png';
import premios from '../../../assets/prizes/premios-mobile.png';

function Prizes({ prizesRef}) {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);
    const isMobile = width < 768;

    return(
        <div className="home-prizes-container" ref={prizesRef}>
            <div className="home-prizes-title-container">
            <img src={isMobile ? prize : prizeDesktop} className="aceleradores-image" alt="cervejas" />
            </div>
        <div className="home-prizes">
            <img src={isMobile ? premios : premiosDesktop} className="aceleradores-image" alt="cervejas" /> 
        </div>
        </div>
    )
}

export default Prizes;