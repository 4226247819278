import React, { useState, useEffect } from 'react';

import './styles.css';

import cervejas from '../../../assets/aceleradores/mecanica-mobile.png';
import cervejasDesktop from '../../../assets/aceleradores/desktop/mecanica-desktop.png';


function Aceleradores({ aceleradoresRef }) {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);
    const isMobile = width < 768;

    return(
        <div className="aceleradores-container" ref={aceleradoresRef}>
            <div className="aceleradores-title-container">
            <img src={isMobile ? cervejas : cervejasDesktop} className="aceleradores-image" alt="cervejas" />
            </div>
            <div className="other-products-button-container" id="button-products">
                <a 
                    className="participating-products-cta"
                    href="/promos"
                >Participe da promoção!</a>
            </div>
        </div>
        
    );
}

export default Aceleradores;