import React, { useState } from 'react';
import axios from 'axios';

import './styles.css';

import save from '../../../assets/icons/save-red.png';
import edit from '../../../assets/icons/edit-red.png';
import trash from '../../../assets/icons/trash-red.png';

import DateInput from '../DateInput/DateInput';
import SelectStatus from '../SelectStatus/SelectStatus';

function DrawTable({ draws, setDraws, adding, setAdding }) {
    const [newData, setNewData] = useState('');
    const [newInicio, setNewInicio] = useState('');
    const [newFim, setNewFim] = useState('');

    const deleteDraw = (idx) => {
        setDraws((prev) => prev.filter((el, i) => {
            if (i === idx) {
                axios
                    .delete(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/sorteios/${el.id}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                        }
                    })
                    .then((response) => console.log(response))
                    .catch((err) => console.log(err));
            }
            return i !== idx;
        }));
    }

    const editDraw = (idx) => {
        setDraws((prev) => prev.map((el, i) => {
            if (i === idx) {
                axios
                    .put(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/sorteios/${el.id}`, {
                        data: newData ? newData : draws[i].data,
                        inicio: newInicio ? newInicio : draws[i].inicio,
                        fim: newFim ? newFim : draws[i].fim,
                    }, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                        }
                    })
                    .then((response) => console.log(response))
                    .catch((err) => console.log(err));
                return { 
                    ...el, 
                    editable: false,
                    data: newData ? newData : draws[i].data,
                    inicio: newInicio ? newInicio : draws[i].inicio,
                    fim: newFim ? newFim : draws[i].fim, 
                };
                }
            return el;
        }));
        setNewData('');
        setNewFim('');
        setNewInicio('');
    }

    const setEditable = (idx) => {
        setDraws((prev) => prev.map((el, i) => {
            return i === idx ? { ...el, editable: true } : el;
        }));
    }

    const saveDraw = (idx) => {
        for (const i in draws) {
            if (Number(i) === idx) {
                axios
                    .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/sorteios`, {
                        tipo: draws[i].tipo.toLowerCase(),
                        data: newData,
                        inicio: newInicio,
                        fim: newFim,
                        promo_id: 3,
                        status: "nao_realizado"
                    }, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                        },
                    })
                    .then((response) => {
                        setDraws((prev) => prev.map((el, i) => {
                            return i === idx ? { 
                                ...el, 
                                editable: false,
                                data: newData,
                                inicio: newInicio,
                                fim: newFim,
                            } : el;
                        }));
                        setNewData('');
                        setNewFim('');
                        setNewInicio('');
                        setAdding(false);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        }
    }
    return(
        <table className="draw-table">
            <thead>
                <tr>
                    <th style={{ opacity: '0.3' }}>#</th>
                    <th>Tipo</th>
                    <th>Data do sorteio</th>
                    <th>Período de participação</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    draws.map((row, idx) => (
                        <tr key={idx}>
                            <td style={{ opacity: '0.3' }}>{idx + 1}</td>
                            <td>{row.tipo.charAt(0).toUpperCase() + row.tipo.slice(1)}</td>
                            <td>
                                {
                                    row.editable ?
                                    <DateInput value={newData || row.data} setValue={setNewData} />
                                    :
                                    <DateInput value={row.data} disabled />
                                }
                            </td>
                            <td>
                                {
                                    row.editable ?
                                    <div className="draw-table-period-container">
                                        <DateInput value={newInicio || row.inicio} setValue={setNewInicio} />
                                        <p>-</p>
                                        <DateInput value={newFim || row.fim} setValue={setNewFim} />
                                    </div>
                                    :
                                    <div className="draw-table-period-container">
                                        <DateInput value={row.inicio} disabled />
                                        <p>-</p>
                                        <DateInput value={row.fim} disabled />
                                    </div>
                                }
                            </td>
                            <td><SelectStatus status={row.status.replace('_', ' ').toUpperCase()} /></td>
                            <td>
                                {
                                    row.editable ?
                                        (row.data.length || newData) && (row.inicio.length || newInicio) && (row.fim.length || newFim) ?
                                        <img src={save} alt="save-icon" className="table-action-icon" 
                                        onClick={() => {
                                            if (adding) saveDraw(idx);
                                            else editDraw(idx);
                                        }} 
                                        style={{ width: '22px', height: '22px' }} />
                                        :
                                        <img src={save} alt="save-icon" className="table-action-icon-disabled" style={{ width: '22px', height: '22px' }} />
                                    : 
                                    draws.filter((el) => el.editable).length ?
                                    <img src={edit} alt="edit-icon" className="table-action-icon-disabled" />
                                    :
                                    <img src={edit} alt="edit-icon" className="table-action-icon" onClick={() => setEditable(idx)} />
                                }
                                {
                                    draws.filter((el) => el.editable).length ?
                                    <img src={trash} alt="trash-icon" className="table-action-icon-disabled" />
                                    :
                                    <img src={trash} alt="trash-icon" className="table-action-icon" onClick={() => deleteDraw(idx)} />
                                }
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default DrawTable;