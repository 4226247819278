import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchResults = (sorteioId) => {
    const [results, setResults] = useState([]);
    useEffect(() => {
        axios
            .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/resultados?sorteio_id=${sorteioId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                }
            })
            .then((response) => {
                console.log(response.data.data);
                setResults(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [sorteioId]);

    return results;
}

export default useFetchResults;