import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchLogs = (setLoading, setError) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/logs`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
        },
      })
      .then((response) => {
        setLoading(false);
        setError(false);
        setLogs(response.data.data);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [setLoading, setError]);

  return { logs };
};

export default useFetchLogs;
