import React, { useState } from "react";

import './styles.css';

import TopBar from "../../layout/TopBar/TopBar";
import ProfileForm from "../../components/Forms/ProfileForm/ProfileForm";
// import AddressForm from "../../components/Forms/AddressForm/AddressForm";

import useAuthClient from "../../hooks/useAuthClient";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import {
    validateName,
    validateEmail,
    validatePhone,
    // validateCep,
} from '../../validation/index';

function Profile() {

    useAuthClient();
    useSetPageTitle('Promos Juntos+ | Perfil');

    // const [cep, setCep] = useState('');
    // const [cepWarning, setCepWarning] = useState('');

    const [name, setName] = useState(localStorage.getItem('name'));
    const [nameWarning, setNameWarning] = useState('');

    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [emailWarning, setEmailWarning] = useState('');

    const [phone, setPhone] = useState(localStorage.getItem('phone'));
    const [phoneWarning, setPhoneWarning] = useState('');

    // const [street, setStreet] = useState('');
    // const [number, setNumber] = useState('');

    // const [complement, setComplement] = useState('');
    // const [neighborhood, setNeighborhood] = useState('');
    // const [state, setState] = useState('');
    // const [city, setCity] = useState('');
    // const [country, setCountry] = useState('');

    const handleName = (inputedName) => validateName(inputedName, setName, setNameWarning);
    const handleEmail = (inputedEmail) => validateEmail(inputedEmail, setEmail, setEmailWarning);
    const handlePhone = (inputedPhone) => validatePhone(inputedPhone, setPhone, setPhoneWarning);
    // const handleCep = (inputedCep) => validateCep(inputedCep, setCep, setCepWarning);

    return(
        <div className="background">
            <TopBar />   
            <div className="profile-background">
                <ProfileForm
                    matricula={localStorage.getItem('matricula')}
                    cpf={localStorage.getItem('doc')}
                    name={name}
                    setName={handleName}
                    nameWarning={nameWarning}
                    setNameWarning={setNameWarning}
                    email={email}
                    setEmail={handleEmail}
                    setEmailWarning={setEmailWarning}
                    emailWarning={emailWarning}
                    phone={phone}
                    setPhone={handlePhone}
                    setPhoneWarning={setPhoneWarning}
                    phoneWarning={phoneWarning}
                />
                {/* <AddressForm 
                    cep={cep}
                    setCep={handleCep}
                    cepWarning={cepWarning}
                    setCepWarning={setCepWarning}
                    street={street}
                    setStreet={setStreet}
                    number={number}
                    setNumber={setNumber}
                    complement={complement}
                    setComplement={setComplement}
                    neighborhood={neighborhood}
                    setNeighborhood={setNeighborhood}
                    state={state}
                    setState={setState}
                    city={city}
                    setCity={setCity}
                    country={country}
                    setCountry={setCountry}
                /> */}
            </div>        
        </div>
    )
}

export default Profile;