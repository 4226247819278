import React, { useState, useEffect } from 'react';

import './styles.css';

import produtosDesktop from '../../../assets/produtos/produtos-desktop.png';
import produtos from '../../../assets/produtos/produtos-mobile.png';
import productsDesktop from '../../../assets/produtos/products-desktop.png';
import products from '../../../assets/produtos/products-mobile.png';

function Products({ productsRef }) {

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);
    const isMobile = width < 768;

    return(
        <div className="other-products-container" ref={productsRef}>
            <div className="other-products-title-container">
            <img src={isMobile ? produtos : produtosDesktop} className="produtos-title" alt="produtos" />
            </div>
            <img src={isMobile ? products : productsDesktop} className="products-image" alt="products" />
            <div className="other-products-button-container">
                <a 
                    className="participating-products-cta"
                    href="https://onelink.to/juntsplusbrv4" 
                    target="_blank"
                    rel="noreferr noreferrer"
                >Confira nossos produtos</a>
            </div>
            <div className="other-products-disaclaimer-container">
                <p><span className="disclaimer-clickable">
                    <a 
                        href="https://tobyvaidethere.com/regulamento" 
                        target="_blank"
                        rel="noreferr noreferrer"
                    >Confira aqui</a></span>. o regulamento</p>
            </div>
        </div>
    )
}

export default Products;