import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import NavBar from "../../layout/NavBar/NavBar";
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import useRememberMe from "../../hooks/useRememberMe";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import {
    validateDocOrEmail,
    validatePassword,
} from '../../validation/index';

function Login() {
    const navigate = useNavigate();

    useRememberMe();
    useSetPageTitle('Promos Juntos+ | Entrar');

    const [doc, setDoc] = useState('');
    const [docWarning, setDocWarning] = useState('');

    const [password, setPassword] = useState('');
    const [passwordWarning, setPasswordWarning] = useState('');

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleDoc = (inputedDoc) => validateDocOrEmail(inputedDoc, setDoc, setDocWarning);
    const handlePassword = (inputedPassword) => validatePassword(inputedPassword, setPassword, setPasswordWarning);

    const tryLogginIn = (e) => {
        e.preventDefault();
        if (!doc.length) setDocWarning('Este é um campo obrigatório');
        if (!password.length) setPasswordWarning('Este é um campo obrigatório');
        if (!doc.length || docWarning.length || !password.length || passwordWarning.length) {
            return;
        }
        setLoading(true);
        axios
            .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/client/login`, {
                user: doc,
                password: password
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setDocWarning('');
                    setPasswordWarning('');
                    localStorage.setItem('doc', response.data.client.doc);
                    localStorage.setItem('id', response.data.client.id);
                    localStorage.setItem('accessToken', response.data.accessToken);
                    localStorage.setItem('name', response.data.client.nome);
                    localStorage.setItem('phone', response.data.client.telefone);
                    localStorage.setItem('email', response.data.client.email);
                    localStorage.setItem('matricula', response.data.client.matricula);
                    localStorage.setItem('ElegivelPromo', response.data.client.ElegivelPromo);
                    localStorage.setItem('PromoAtiva', response.data.client.PromoAtiva);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'login-ok',
                    });
                    setErrorMessage('');
                    navigate('/home');
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.status === 404) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 404,
                        error_message: 'Elegível não encontrado - 404'
                    });
                    setDocWarning('CPF/CNPJ ou Email não cadastrado');
                    setPasswordWarning('');
                    //setErrorMessage('Elegível não encontrado - 404');
                    return;
                }
                if (err.response.status === 401) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 401,
                        error_message: 'Elegível encontrado mas é bloqueado para a promoção atual - 401'
                    });
                    setDocWarning('');
                    setPasswordWarning('Senha incorreta');
                    //setErrorMessage('Elegível encontrado mas é bloqueado para a promoção atual - 401');
                    return;
                }
                if (err.response.status === 400) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 400,
                        error_message: 'Elegível já possui cadastro - 400'
                    });
                    setDocWarning('');
                    setPasswordWarning('');
                    //setErrorMessage('Elegível já possui cadastro - 400');
                    return;
                }
            });
    };

    return (
        <div>
            <NavBar />
            <div className="login-page-container">
                <LoginForm
                    doc={doc}
                    setDoc={handleDoc}
                    docWarning={docWarning}
                    password={password}
                    setPassword={handlePassword}
                    passwordWarning={passwordWarning}
                    onClick={tryLogginIn}
                    dontHaveAnAccount={() => navigate('/signin')}
                    forgotPassword={() => navigate("/esqueci-minha-senha")}
                    loading={loading}
                />
                {errorMessage && (
                    <div className="login-form-error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;
