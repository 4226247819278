import React, { useState } from 'react';

import SearchBar from '../../TextInput/SearchBar';

import './styles.css';

import arrow from '../../../assets/icons/arrow.png'

function LogTable({ logs }) {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const handlePageChange = (increment) => {
        const totalNumbers = logs.length;
        if (page + increment <= Math.ceil(totalNumbers/20) && (page + increment > 0)) {
            setPage(page + increment);
        }
    }

    const handleSearch = (search) => {
        setSearch(search);
        setPage(1);
    }
    return(
        <div>
            <div className="logs-table-pagination-container">
                <SearchBar setValue={handleSearch} rubik />
                <img src={arrow} alt="arrow-icon" className={`numbers-table-arrow-icon ${page === 1 ? "numbers-table-arrow-icon-disabled" : ""}`} onClick={() => handlePageChange(-1)} />
                <p className="logs-table-page">{page}</p>
                <img src={arrow} alt="arrow-icon" className={`numbers-table-arrow-reverse-icon ${page === Math.ceil(logs.length/20) ? "numbers-table-arrow-icon-disabled" : ""}`} onClick={() => handlePageChange(1)} />
            </div>
            <table className="log-table">
                <thead>
                    <tr>
                        <th style={{ opacity: '0.3' }}>#</th>
                        <th>Quando</th>
                        <th>Descrição</th>
                        <th>Usuário</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        logs
                        .filter(row => (row.content.includes(search) || row.client.email.includes(search)))
                        .filter((row, index) => (index >= (page - 1) * 20 && index < page * 20))
                        .map((row, idx) => (
                            <tr key={idx}>
                                <td style={{ opacity: '0.3' }}>{idx + 1 + (page - 1) * 20}</td>
                                <td>{`${row.created_at.substring(0, 10)} - ${row.created_at.substring(11, 16)}h`}</td>
                                <td>{row.content}</td>
                                <td>{row.client.email}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default LogTable;