import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Hamburger from 'hamburger-react';
import useFetchClientsResults from "../../hooks/useFetchClientsResults";
import './styles.css';

import juntosLogo from '../../assets/images/juntos-logo2.png';
import promo from '../../assets/images/toby-there-logo-white.png';
import profile from '../../assets/icons/profile-white.png';
import logout from '../../assets/icons/logout-white.png';
import exclamation from '../../assets/icons/exclamation.png';
import arrow from '../../assets/icons/black-right-arrrow.png';
import tobyThere from "../../assets/images/toby-there.png";

function TopBar() {
    const { pendingPrizes } = useFetchClientsResults();
    const [menuOpened, setMenuOpened] = useState(false);
    const [divW, setDivW] = useState(0);
    const [isPromotionActive, setIsPromotionActive] = useState(false);
    const [registered, setRegistered] = useState(-1);

    const navigate = useNavigate();
    const name = localStorage.getItem('name') ? localStorage.getItem('name').split(' ')[0] : '';

    useEffect(() => {
        const PromoAtiva = localStorage.getItem('PromoAtiva');
        setIsPromotionActive(PromoAtiva === 'true');
    }, []);

    useEffect(() => {
        axios.get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/client/promo/3`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                setRegistered(response.data.participantes);
            }
        })
        .catch((err) => console.log(err));
    }, []);

    const handleMenu = () => {
        setDivW(menuOpened ? 0 : 100);
        setMenuOpened(!menuOpened);
    };

    useEffect(() => {
        if (menuOpened) {
            window.scrollTo({ top: 0, behavior: 'auto' });
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [menuOpened]);

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('name');
        localStorage.removeItem('doc');
        localStorage.removeItem('id');
        localStorage.removeItem('phone');
        localStorage.removeItem('email');
        localStorage.removeItem('matricula');
        localStorage.removeItem('popup_closed');
        localStorage.removeItem('PromoAtiva');
        document.body.style.overflow = 'auto';
        navigate('/login');
    };

    const routes = [
        { route: '/home', navbar: 'Home' },
        { route: '/resultados', navbar: 'Resultados', warning: pendingPrizes > 0 },
        { route: '/numeros', navbar: 'Meus números' },
        { route: '/premios', navbar: 'Meus prêmios', warning: pendingPrizes > 0 },
        { route: '/promos', navbar: 'Promoções' },
        { route: '/perfil', navbar: 'Meu Perfil' },
    ];

    return (
        <div className="topbar-container">
            <div className="topbar-upper-container">
                <div className="topbar-logo-container" onClick={() => navigate('/home')}>
                    <img src={juntosLogo} className="topbar-logo-image" alt="femsa-logo" />
                    {isPromotionActive && <img src={promo} className="topbar-logo-image-promo" alt="promo-logo" />}
                </div>
                <div className="topbar-profile-container">
                    <div className="topbar-profile" onClick={() => navigate('/perfil')}>
                        <img src={profile} className="topbar-profile-icon" alt="profile-icon" />
                        <p className="topbar-profile-greet">Olá, <span className="topbar-profile-name">{name}</span></p>
                    </div>
                    <img src={logout} className="topbar-profile-icon" alt="logout-icon" onClick={handleLogout} />
                </div>
                <div className="topbar-hamburguer" style={{ zIndex: '200' }}>
                    <Hamburger toggled={menuOpened} toggle={handleMenu} color={menuOpened ? '#000000' : "#FFFFFF"} />
                </div>
            </div>
            <div className="topbar-nav-container notranslate">
                {routes.map((route) => (
                    <div
                        key={route.navbar}
                        onClick={() => navigate(route.route)}
                        className={window.location.href.includes(route.route) ? "topbar-nav-item-selected" : "topbar-nav-item"}
                    >
                        <p>{route.navbar.toUpperCase()}</p>
                        {route.warning && <img alt="exclamation" src={exclamation} className="topbar-exclamation-icon" />}
                    </div>
                ))}
            </div>
            <div className="topbar-menu-mobile" style={{ width: `${divW}vw`, padding: `50px ${divW * 0.25}px 92px ${divW * 0.25}px` }}>
                <div style={{ opacity: `${divW / 100}`, display: menuOpened ? 'flex' : 'none', flexDirection: 'column', transition: 'opacity 1s cubic-bezier(.77, .06, 0, .98)', height: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                        <img src={tobyThere} alt="logo" className="logo-promo-mobile" />
                        {routes.slice(0, routes.length - 1).map((route) => (
                            <div
                                key={route.navbar}
                                onClick={() => navigate(route.route)}
                                className={window.location.href.includes(route.route) ? "topbar-menu-nav-item-selected" : "topbar-menu-nav-item"}
                            >
                                {window.location.href.includes(route.route) && <img src={arrow} className="topbar-menu-red-arrow-icon" alt="red-arrow-icon" />}
                                <p style={{ fontSize: '23px', fontWeight: '600', fontFamily: 'Poppins' }}>{route.navbar.toUpperCase()}</p>
                                {route.warning && <img alt="exclamation" src={exclamation} className="topbar-menu-exclamation-icon" />}
                            </div>
                        ))}
                    </div>
                    <div className="topbar-menu-bottom-container">
                        <div className="topbar-menu-nav-bottom" onClick={() => navigate('/perfil')}>
                            <p>PERFIL</p>
                        </div>
                        <div className="topbar-menu-nav-bottom-logout" onClick={handleLogout}>
                            <p>SAIR</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
