const validatePhone = (phone, setPhone, setPhoneWarning) => {
    phone = phone.replaceAll('(', '');
    phone = phone.replaceAll(')', '');
    phone = phone.replaceAll(' ', '');
    phone = phone.replaceAll('-', '');
    if (!phone.length) {
        setPhoneWarning('');
        setPhone('');
        return;
    }
    if (!/^[0-9]+$/.test(phone)) return;
    if ((/^[0-9]+$/.test(phone) && phone.length <= 11) || !phone.length) {
        let phonePointer = 0;
        let formattedPhone = '';
        while (phonePointer < phone.length) {
          if (phonePointer === 0) formattedPhone += '(';
          if (phonePointer === 2) formattedPhone += ') ';
          if (phonePointer === 7) formattedPhone += '-';
          formattedPhone += phone[phonePointer];
          phonePointer++;
        }
        setPhone(formattedPhone);
    }
    if (phone.length < 10) {
        setPhoneWarning('Insira um telefone válido');
    } else {
        setPhoneWarning('');
    }
}

export default validatePhone;