import React, { useState } from "react";
import TextInput from '../../TextInput/TextInput';
import HideTextInput from '../../TextInput/HideTextInput';
import TitleOn from "../../../layout/TitleOn/TitleOn";
import Checkbox from "../../Checkbox/Checkbox";
import LinearLoader from "../../../assets/loaders/LinearLoader";
import './styles.css';
import promo from '../../../assets/images/toby-there.png';
import danger from '../../../assets/icons/danger.png';

function SigninForm({
    doc, setDoc, docWarning, docRef,
    name, setName, nameWarning, nameRef,
    email, setEmail, emailWarning, emailRef,
    phone, setPhone, phoneWarning, phoneRef,
    password, setPassword, passwordWarning, passwordRef,
    confirmPassword, setConfirmPassword, confirmPasswordWarning, confirmPasswordRef,
    onClick, haveAnAccount, invalidDoc, alreadySigned,
    agreeWithRegulation, setAgreeWithRegulation, agreeWithRegulationWarning, setAgreeWithRegulationWarning,
    agreeWithSharing, setAgreeWithSharing, agreeWithSharingWarning, setAgreeWithSharingWarning,
    loading
}) {
    const [errorMessage] = useState('');

    return (
        <form className="signin-container">
            <TitleOn title='Cadastre-se' />
            <div className="signin-form-container">
                <img src={promo} className="promo-kv-signin" alt="promo-kv" />
                <p className="signin-form-title">Crie sua conta</p>
                <p className="signin-form-subtitle">Já tem uma conta? <span className="enter-here" onClick={haveAnAccount}>Entre aqui</span></p>
                {
                    invalidDoc ?
                        <div className="signin-form-invalid-cpf-border">
                            <div className="signin-form-invalid-cpf-container">
                                <div className="signin-form-invalid-cpf-warning-container">
                                    <img src={danger} alt="danger" className="signin-form-invalid-cpf-warning-img" />
                                    <p className="signin-form-invalid-cpf-warning-text">Atenção!</p>
                                </div>
                                <p className="signin-form-invalid-cpf-info">Atualmente, o CPF inserido não é válido para participar das promoções Coca-Cola FEMSA</p>
                                <a
                                    className="signin-form-invalid-cpf-button"
                                    href='https://coca-cola-femsa.com.br/cadastro/formulario/'
                                    target="_blank"
                                    rel="noreferr noreferrer"
                                    id="quero-ser-cliente"
                                >Quero ser cliente</a>
                            </div>
                        </div>
                        : false
                }
                {
                    alreadySigned ?
                        <div className="signin-form-invalid-cpf-border">
                            <div className="signin-form-invalid-cpf-container">
                                <div className="signin-form-invalid-cpf-warning-container">
                                    <img src={danger} alt="danger" className="signin-form-invalid-cpf-warning-img" />
                                    <p className="signin-form-invalid-cpf-warning-text">Atenção!</p>
                                </div>
                                <p className="signin-form-invalid-cpf-info">Esse CPF/CNPJ já está registrado em nosso portal de promoções. Clique aqui para fazer o login e acessar a sua conta.</p>
                                <a
                                    className="signin-form-invalid-cpf-button"
                                    href='/login'
                                >Entrar agora</a>
                            </div>
                        </div>
                        : false
                }
                {
                    errorMessage && (
                        <div className="signin-form-invalid-cpf-border">
                        <div className="signin-form-error-container">
                            <img src={danger} alt="danger" className="signin-form-invalid-cpf-warning-img" />
                            <p className="signin-form-invalid-cpf-warning-text">Atenção!</p>
                            <p className="signin-form-error-text">{errorMessage}</p>
                        </div>
                    </div>
                    )
                }
                <div className="signin-form-inputs-container">
                    <div className="signin-form-row">
                        <TextInput
                            label={<span className="black-label">Seu CPF/CNPJ do estabelecimento*</span>}
                            placeholder='xxx.xxx.xxx-xx'
                            type="text"
                            value={doc}
                            setValue={setDoc}
                            warningMessage={docWarning}
                            inputRef={docRef}
                        />
                        <TextInput
                            label={<span className="black-label">Razão social do estabelecimento*</span>}
                            type="text"
                            placeholder="Mercadinho Toby LTDA"
                            value={name}
                            setValue={setName}
                            warningMessage={nameWarning}
                            inputRef={nameRef}
                        />
                    </div>
                    <div className="signin-form-row">
                        <TextInput
                            label={<span className="black-label">Seu email*</span>}
                            placeholder="exemplo@email.com"
                            type="email"
                            value={email}
                            setValue={setEmail}
                            warningMessage={emailWarning}
                            inputRef={emailRef}
                        />
                        <TextInput
                            label={<span className="black-label">Seu telefone*</span>}
                            placeholder="(xx) xxxxx-xxxx"
                            type="text"
                            value={phone}
                            setValue={setPhone}
                            warningMessage={phoneWarning}
                            inputRef={phoneRef}
                        />
                    </div>
                    <div className="signin-form-row">
                        <HideTextInput
                            label={<span className="black-label">Sua senha*</span>}
                            placeholder="Insira no mínimo 6 caracteres"
                            value={password}
                            setValue={setPassword}
                            warningMessage={passwordWarning}
                            inputRef={passwordRef}
                        />
                        <HideTextInput
                            label={<span className="black-label">Confirme sua senha*</span>}
                            placeholder="Insira a mesma senha"
                            value={confirmPassword}
                            setValue={setConfirmPassword}
                            warningMessage={confirmPasswordWarning}
                            inputRef={confirmPasswordRef}
                        />
                    </div>
                    <div className="signin-form-checkbox-container">
                        <Checkbox
                            value={agreeWithRegulation}
                            setValue={setAgreeWithRegulation}
                            warning={agreeWithRegulationWarning}
                            setWarning={setAgreeWithRegulationWarning}
                            text={<span className="black-label">Estou ciente e concordo integralmente com o Regulamento da Promoção, com os Termos e com a Política de Privacidade da Coca-Cola Femsa, bem como estou ciente e dou autorização para que a Coca-Cola Femsa use meus dados de contato e interações para me mandar comunicações referente a promoção que estou me cadastrando e compartilhe meu dados com empresas parceiras, respeitadas as diretrizes da Lei Geral de Proteção de Dados (Lei 13.709/18).</span>} />
                        <Checkbox
                            value={agreeWithSharing}
                            setValue={setAgreeWithSharing}
                            warning={agreeWithSharingWarning}
                            setWarning={setAgreeWithSharingWarning}
                            text={<span className="black-label">Concordo que a Coca-Cola FEMSA use meus dados de contato e interações para me mandar comunicações de marketing e informações sobre produtos.</span>} />
                    </div>
                </div>
                <button type="submit" className="signin-form-button" onClick={onClick}>
                    {
                        loading ? <LinearLoader white /> : <p>Criar conta</p>
                    }
                </button>
            </div>
        </form>
    )
}

export default SigninForm;
