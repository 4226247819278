import { useEffect } from "react";

const useSetPageTitle = (pageTitle) => {

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

}

export default useSetPageTitle;