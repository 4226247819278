import React from 'react';

import './styles.css';

function SelectStatus({ status }) {
    console.log(status)
    return(
        <div className={`status-container ${status === "FINALIZADO" ? "status-done" : "status-not-done"}`}>
            <p className="status-text">{status}</p>
        </div>
    )
}

export default SelectStatus;