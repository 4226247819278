import React, { useContext } from "react";

import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useSetPageTitle from "../../../hooks/useSetPageTitle";
import AdminContext from "../../../contexts/AdminContext";

import './styles.css';

function HomeAdmin() {
    const { currentPromo } = useContext(AdminContext);
    useAuthAdmin();
    useSetPageTitle('Promos Juntos+ | Admin');
    return(
        <div className="admin-page-container">
            <p className="admin-page-title">{currentPromo?.label}</p>
        </div>
    )
}

export default HomeAdmin;