import React, { useState, useEffect } from "react";

import TopBar from "../../layout/TopBar/TopBar";
import Status from "../../components/Status/Status";
import SelectInput from "../../components/SelectInput/SelectInput";
import SearchBar from '../../components/TextInput/SearchBar';
import TitleOn from '../../layout/TitleOn/TitleOn';

import useAuthClient from '../../hooks/useAuthClient';
import useFetchVouchers from "../../hooks/useFetchVouchers";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import './styles.css';

import sort from '../../assets/icons/sort-red.png';
import arrow from '../../assets/icons/arrow.png';

function MyNumbers() {

    useAuthClient();
    useSetPageTitle('Promos Juntos+ | Números');
    const [numbers, setNumbers] = useState([]);
    useFetchVouchers(setNumbers);

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);
    const isMobile = width < 768;

    const [filter, setFilter] = useState('Todos');
    const [searchNumber, setSearchNumber] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const rowOptions = [
        { value: '10', label: '10 linhas por página' },
        { value: '50', label: '50 linhas por página' },
        { value: '100', label: '100 linhas por página' },
        { value: '200', label: '200 linhas por página' },
    ]

    const handleSearch = (text) => {
        setPage(1);
        if (text.length <= 10) setSearchNumber(text);
    }

    const handlePageChange = (increment) => {
        const totalNumbers = 
            numbers
                .filter(number => {
                    if (filter === 'Todos') return true;
                    if (filter === 'Sorteados') return number.status === 'sorteado';
                    if (filter === 'Aguardando') return number.status === 'aguardando'
                    return false;
                })
                .length;
        if (page + increment <= Math.ceil(totalNumbers/rowsPerPage) && (page + increment > 0)) {
            setPage(page + increment);
        }
    }

    const compareDates = (a, b) => {
        const dateA = new Date(a.created_at.split('/').reverse().join('/'));
        const dateB = new Date(b.created_at.split('/').reverse().join('/'));
        return dateA - dateB;
    }      

    const sortDates = () => {
        let increasing = true;
        for (let i = 0; i < numbers.length - 1; i++) {
            if (compareDates(numbers[i], numbers[i + 1]) > 0) increasing = false;
        }
        if (increasing) setNumbers([...numbers].sort(compareDates).reverse()); 
        else setNumbers([...numbers].sort(compareDates));
    }

    return(
        <div className="background">
            <TopBar />     
            <div className="numbers-background">
                <div className="numbers-table-title-select">
                    <TitleOn title='Meus números da sorte' />
                </div>
                <div className="numbers-table-container">
                    <div className="numbers-table-filters-container">
                        <div className={filter === 'Todos' ? "numbers-table-filter-selected" : "numbers-table-filter"} onClick={() => setFilter('Todos')}>
                            <p>Todos</p>
                            <div className={filter === 'Todos' ? "numbers-table-filter-quantity-selected" : "numbers-table-filter-quantity"}>
                                <p>{numbers.length}</p>
                            </div>
                        </div>
                        <div className={filter === 'Aguardando' ? "numbers-table-filter-selected" : "numbers-table-filter"} onClick={() => {
                            setFilter('Aguardando');
                            setPage(1);
                        }}>
                            <p>Aguardando</p>
                            <div className={filter === 'Aguardando' ? "numbers-table-filter-quantity-selected" : "numbers-table-filter-quantity"}>
                                <p>{numbers.filter(number => number.status === 'aguardando').length}</p>
                            </div>
                        </div>
                        <div className={filter === 'Sorteados' ? "numbers-table-filter-selected" : "numbers-table-filter"} onClick={() => {
                            setFilter('Sorteados');
                            setPage(1);
                        }}>
                            <p>Sorteados</p>
                            <div className={filter === 'Sorteados' ? "numbers-table-filter-quantity-selected-green" : "numbers-table-filter-quantity"}>
                                <p>{numbers.filter(number => number.status === 'sorteado').length}</p>
                            </div>
                        </div>
                    </div>
                    <div className="numbers-table-search-container">
                        <SearchBar value={searchNumber} setValue={handleSearch} placeholder="Procure um número" />
                        <div className="numbers-table-pagination-container">
                            <SelectInput
                                options={rowOptions}
                                value={rowOptions.filter(option => option.value === String(rowsPerPage))}
                                setOption={(value) => {
                                    setPage(1);
                                    setRowsPerPage(Number(value.value));
                                }}
                            />
                            <img src={arrow} alt="arrow-icon" className={`numbers-table-arrow-icon ${page === 1 ? "numbers-table-arrow-icon-disabled" : ""}`} onClick={() => handlePageChange(-1)} />
                            <p className="numbers-table-page">{page}</p>
                            <img src={arrow} alt="arrow-icon" className={`numbers-table-arrow-reverse-icon ${page === Math.ceil(numbers.length/rowsPerPage) ? "numbers-table-arrow-icon-disabled" : ""}`} onClick={() => handlePageChange(1)} />
                        </div>
                    </div>
                    <table className="numbers-table">
                        <thead>
                            <tr>
                                <th className="numbers-table-numeration">#</th>
                                <th>{isMobile ? 'Nº sorte' : 'Número da sorte'}</th>
                                <th>{!isMobile ? "Quando foi gerado" : "Data"}<img src={sort} alt="sort-icon" className="numbers-table-sort-icon" onClick={sortDates} /></th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                numbers
                                    .filter(number => {
                                        if (filter === 'Todos') return true;
                                        if (filter === 'Sorteados') return number.status === 'sorteado';
                                        if (filter === 'Aguardando') return number.status === 'aguardando'
                                        return false;
                                    })
                                    .filter(number => number.code.includes(searchNumber))
                                    .filter((number, index) => (index >= (page - 1) * rowsPerPage && index < page * rowsPerPage))
                                    .map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ opacity: '0.3' }}>{index + 1 + (page - 1) * rowsPerPage}</td>
                                            <td className='numbers-table-luck-number'>{item.code}</td>
                                            <td>{item.data_pedido}</td>
                                            <td><Status status={item.status} /></td>
                                        </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>       
        </div>
    )
}

export default MyNumbers;