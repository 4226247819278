import React, { useState, useEffect } from 'react';

import winnerMobile from '../../../assets/banner/vai-de-there-white.png';
import winnerDesktop from '../../../assets/banner/vai-de-there-white.png';

//import close from '../../../assets/icons/close-grey.png';//

import './styles.css';

function PopupSuccess({ setPopup }) {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);
    const isMobile = width < 768;

    const handleClose = () => {
        localStorage.removeItem('firstTime');
        setPopup(false);
    }

    return(
        <div className="popup-success-background">
            <div className="popup-success-container">
                <img src={isMobile ? winnerMobile : winnerDesktop} alt="banner-week" className="popup-success-img" />
                    <p className="popup-success-title">Falta pouco!</p>
                    <p className="popup-success-subtitle">Cadastro efetuado com sucesso!</p>
                    <p className="popup-success-subsubtitle">Você precisa fazer um pedido pelo Juntos+ para participar da promoção</p>
                    <p className="popup-success-cta" onClick={handleClose}>Ok, entendi</p>
            </div>
        </div>
    )
}

export default PopupSuccess;