import React from 'react';

import './styles.css';

import excel from '../../../assets/icons/excel.png';

function DownloadModel({ label, onClick }) {
    return(
        <div className="download-model-container" onClick={onClick}>
            <img src={excel} alt="excel-icon" className="excel-icon" />
            <p className="download-model-label">{label}</p>
        </div>
    )
}

export default DownloadModel;