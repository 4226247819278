import React, { useState } from "react";

import TopBar from "../../layout/TopBar/TopBar";
import DrawBox from "../../components/DrawBox/DrawBox";
import SearchBar from '../../components/TextInput/SearchBar';
import TitleOn from '../../layout/TitleOn/TitleOn';

import useAuthClient from '../../hooks/useAuthClient';
import useFetchDraws from "../../hooks/useFetchDraws";
import useFetchResults from "../../hooks/useFetchResults";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import arrow from '../../assets/icons/arrowdown.png';
import plug from '../../assets/images/plug-macho.png';
import './styles.css';

function Results() {

    useAuthClient();
    useSetPageTitle('Promos Juntos+ | Resultados');

    const [dropdown, setDropdown] = useState(false);
    const [searchInfo, setSearchInfo] = useState('');
    const [drawsWeek, setDrawsWeek] = useState([]);
    const [drawsMonth, setDrawsMonth] = useState([]);
    const [drawsFinal, setDrawsFinal] = useState([]);

    const [sorteio, setSorteio] = useState({ data: '0000-00-00', tipo: '000', id: '-1' });

    useFetchDraws(1, setDrawsWeek, setDrawsMonth, setDrawsFinal, setSorteio);
    const results = useFetchResults(sorteio.id);

    const formatDate = (unformattedDate) => unformattedDate.split('-').reverse().join('/');

    return(
        <div className="background">
            <TopBar /> 
            <div className="results-background">
                <div className="results-title-select">
                    <TitleOn title='Confira os ganhadores' />
                </div>
                <p className="results-dropdown-title">Selecione o sorteio</p>
                <div className={dropdown ? "results-dropdown-selected" : "results-dropdown"} onClick={() => setDropdown(!dropdown)}>
                    <img src={arrow} alt="arrow-down" className={dropdown ? "results-dropdown-arrow-up" : "results-dropdown-arrow"} />
                    <p>{`Sorteio ${sorteio.tipo.charAt(0).toUpperCase() + sorteio.tipo.slice(1)} - ${formatDate(sorteio.data)}`}</p>
                </div>
                <div className={dropdown ? "results-dropdown-container-visible" : "results-dropdown-container-hidden"}>
                    <div className={dropdown ? "results-dropdown-content-visible" : "results-dropdown-content-hidden"}>
                    <p className="results-dropdown-subtitle">Resultados dos Sorteios Semanais</p>
                        <div className="results-dropdown-draw-boxes-container">
                        {
                                drawsWeek.map(draw => (
                                    <DrawBox
                                        key={`Sorteio semanal - ${draw.data}`}
                                        name={`Sorteio semanal - ${formatDate(draw.data)}`}
                                        startDate={formatDate(draw.inicio)}
                                        endDate={formatDate(draw.fim)}
                                        selected={draw.selected}
                                        disabled={draw.status === 'nao_realizado'}
                                        onClick={() => {
                                            if (draw.status !== 'nao_realizado') {
                                                setDropdown(false);
                                                setSorteio(draw);
                                            }
                                        }}
                                    />
                                ))
                            }
                        </div>
                        <p className="results-dropdown-subtitle">Resultados dos Sorteios Mensais</p>
                        <div className="results-dropdown-draw-boxes-container">
                            {
                                drawsMonth.map(draw => (
                                    <DrawBox
                                        key={`Sorteio mensal - ${draw.data}`}
                                        name={`Sorteio mensal - ${formatDate(draw.data)}`}
                                        startDate={formatDate(draw.inicio)}
                                        endDate={formatDate(draw.fim)}
                                        selected={draw.selected}
                                        disabled={draw.status === 'nao_realizado'}
                                        onClick={() => {
                                            if (!draw.disabled && !draw.selected) {
                                                if (draw.status !== 'nao_realizado') {
                                                    setDropdown(false);
                                                    setSorteio(draw);
                                                }
                                            }
                                        }}
                                    />
                                ))
                            }
                        </div>
                        <p className="results-dropdown-subtitle">Resultados do Sorteio Final</p>
                        <div className="results-dropdown-draw-boxes-container">
                        {
                                drawsFinal.map(draw => (
                                    <DrawBox
                                        key={`Sorteio final - ${draw.data}`}
                                        name={`Sorteio final - ${formatDate(draw.data)}`}
                                        startDate={formatDate(draw.inicio)}
                                        endDate={formatDate(draw.fim)}
                                        selected={draw.selected}
                                        disabled={draw.status === 'nao_realizado'}
                                        onClick={() => {
                                            if (draw.status !== 'nao_realizado') {
                                                setDropdown(false);
                                                setSorteio(draw);
                                            }
                                        }}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>  
                <p className="results-table-title">{`Sorteio ${sorteio.tipo.charAt(0).toUpperCase() + sorteio.tipo.slice(1)} - ${formatDate(sorteio.data)}`}</p>
                <div className="results-table-search-container">
                    <SearchBar 
                        value={searchInfo}
                        setValue={setSearchInfo}
                        placeholder="Procure informações"
                    />
                </div>
                <table className="results-table">
                    <thead>
                        <tr>
                            <th style={{ opacity: '0.3', width: '120px', padding: '0px' }}>#</th>
                            <th>Razão social</th>
                            <th>Número da sorte</th>
                            <th>Prêmio</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        results
                            .filter(item => item.client_id === Number(localStorage.getItem('id')))
                            .map((item, index) => (
                                <tr key={index - 100} className="table-you-win-row">
                                    <td style={{ opacity: '0.3' }}>!</td>
                                    <td>{item.razao_social}</td>
                                    <td>{item.voucher}</td>
                                    <td>{item.premio.nome}</td>
                                </tr>
                            ))
                    }
                    {
                        results
                            .filter(item => item.client_id !== Number(localStorage.getItem('id')))
                            .filter(item => (
                                String(item.register).includes(searchInfo) ||
                                item.razao_social.toLowerCase().includes(searchInfo.toLowerCase()) ||
                                String(item.voucher).includes(searchInfo) ||
                                item.premio.nome.toLowerCase().includes(searchInfo.toLowerCase())
                            ))
                            .map((item, index) => (
                                <tr key={index}>
                                    <td style={{ opacity: '0.3' }}>{index + 1}</td>
                                    <td>{item.razao_social}</td>
                                    <td>{item.voucher}</td>
                                    <td>{item.premio.nome}</td>
                                </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>           
        </div>
    )
}

export default Results;