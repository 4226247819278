import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import useCheckRoleSideBar from "../../../hooks/admin/useCheckRoleSideBar";
import SelectInput from '../../../components/SelectInput/SelectInput';
import AdminContext from '../../../contexts/AdminContext';

import './styles.css';

import add from '../../../assets/icons/add.png';
import logout from '../../../assets/icons/logout-white.png';
import navLinks from "./links";

function SideBarAdmin() {
    const navigate = useNavigate();
    const [links, setLinks] = useState(navLinks);

    const { promos, currentPromo, setCurrentPromo } = useContext(AdminContext);

    const handleLogout = () => {
        localStorage.removeItem('adminAccessToken');
        localStorage.removeItem('role');
        navigate('/admin');
    }

    useCheckRoleSideBar(setLinks);

    return(
        <div className="admin-sidebar-container">
            <p className="admin-sidebar-dropdown-title">Promoção</p>
            <div className="admin-sidebar-dropdown-container">
                <SelectInput
                options={promos}
                value={currentPromo}
                setOption={(value) => setCurrentPromo(value)}
                rubik
                />
            </div>
            <div className="admin-sidebar-add-container">
                <img src={add} className="admin-sidebar-add-icon" alt="add-icon" />
                <p className="admin-sidebar-link-text">Adicionar promoção</p>
            </div>
            <div className="admin-sidebar-line"></div>
            <div className="admin-sidebar-links-container">
                {
                    links.map((link) => (
                        <div
                            key={link.label}
                            className={`admin-sidebar-link-container ${window.location.pathname.includes(link.path) ? 'selected-link-container' : ''}`}
                            onClick={() => navigate(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')) + `/${link.path}`)}
                        >
                            <img src={link.icon} alt={`${link.path}-icon`} className="admin-sidebar-link-icon" />
                            <p className={`admin-sidebar-link-text ${window.location.pathname.includes(link.path) ? 'selected-link-text' : ''}`}>{link.label}</p>
                        </div>
                    ))
                }
            </div>
            <div className="admin-sidebar-logout-container" onClick={handleLogout}>
                <img src={logout} alt="logout-icon" className="admin-sidebar-link-icon" />
                <p className="admin-sidebar-link-text">Sair</p>
            </div>
        </div>
    )
}

export default SideBarAdmin;