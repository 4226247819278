import { useState, useEffect } from 'react';
import axios from 'axios';

const useHaveVouchers = () => {
    const [haveVouchers, setHaveVoucers] = useState(false);

    useEffect(() => {
        axios
            .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/client/me`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                  },
            })
            .then((response) => {
                setHaveVoucers(response.data.client.haveVouchers);
                localStorage.setItem('ElegivelPromo', response.data.client.ElegivelPromo);
                localStorage.setItem('PromoAtiva', response.data.client.PromoAtiva);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setHaveVoucers])

    return haveVouchers;
}

export default useHaveVouchers;
