import React, { useState } from "react";
import axios from 'axios';

import NavBar from "../../layout/NavBar/NavBar";
import ResetPasswordForm from '../../components/Forms/ResetPasswordForm/ResetPasswordForm';

import { useNavigate, useSearchParams } from "react-router-dom";

import {
    validatePassword,
    validateConfirmPassword,
} from '../../validation/index';

function ResetPassword() {

    const [params] = useSearchParams();
    const token = params.get('token');
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [passwordWarning, setPasswordWarning] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordWarning, setConfirmPasswordWarning] = useState('');

    const [loading, setLoading] = useState(false);

    const handlePassword = (inputedPassword) => validatePassword(inputedPassword, setPassword, setPasswordWarning);
    const handleConfirmPassword = (inputedPassword) => validateConfirmPassword(inputedPassword, setConfirmPassword, setConfirmPasswordWarning, password);

    const tryResetingPassword = (e) => {
        e.preventDefault();
        if (!password.length) setPasswordWarning('Este é um campo obrigatório');
        if (!confirmPassword.length) setConfirmPasswordWarning('Este é um campo obrigatório');
        if (password !== confirmPassword) setConfirmPasswordWarning('As senhas devem ser iguais');
        if (!password.length || passwordWarning.length
            || !confirmPassword.length || confirmPasswordWarning.length) {
                return;
            }
        setLoading(true);
        axios
            .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/verificar-token`, {
                token: token,
                password: password,
            })
            .then((response) => {
                setLoading(false);
                navigate('/login');
            })
            .catch((err) => {
                setLoading(false);
                console.log(err)
            })
    }


    return(
        <div className="background">
            <NavBar />
            <div className="forgot-password-page-container">
                <ResetPasswordForm
                    password={password}
                    setPassword={handlePassword}
                    passwordWarning={passwordWarning}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={handleConfirmPassword}
                    confirmPasswordWarning={confirmPasswordWarning}
                    onClick={tryResetingPassword}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default ResetPassword;