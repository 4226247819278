import React from 'react';

import './styles.css';

function DateInput({ value, setValue, disabled }) {
    return(
        <input
            type="date"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={`${disabled ? "date-input-disabled" : "date-input"}`}
            disabled={disabled}
        />
    )
}

export default DateInput;