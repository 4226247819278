import React from 'react';

import './styles.css';

function TitleOn({ title }) {
    return(
                    <div className="title-on-layer-1">
                        <p className="title-on-text">{title}</p>
                    </div>
            )
}

export default TitleOn;