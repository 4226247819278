import React from "react";
import { useNavigate } from 'react-router-dom';
import LinearLoader from "../../../assets/loaders/LinearLoader";
import TitleOn from "../../../layout/TitleOn/TitleOn";
import './styles.css';

function PhoneNumberConfirmationForm({ phoneNumber, loading }) {
    const navigate = useNavigate();

    const handleSendSMS = () => {
        // Adicione a lógica para enviar o SMS aqui
        console.log("SMS enviado para:", phoneNumber);
        // Navegue para a próxima tela ou exiba uma mensagem de sucesso
    };

    return (
        <div className="background">
            <div className="phone-number-confirmation-container">
                <TitleOn title='Confirmação de Telefone' />
                <div className="phone-number-card">
                    <div className="phone-number-form-container">
                        <p className="phone-number-form-title">Verificação de Telefone</p>
                        <p className="phone-number-form-subtitle">Seu número de telefone é: {phoneNumber}</p>
                        <p className="phone-number-form-text">Enviaremos um código de verificação para este número.</p>
                        <button type="button" className="phone-number-form-button" onClick={handleSendSMS}>
                            {loading ? <LinearLoader white /> : <p>Enviar SMS</p>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PhoneNumberConfirmationForm;
