import React, { useState } from "react";

import './styles.css';

import LogTable from '../../../components/Admin/LogTable/LogTable';

import DefaultLoader from "../../../assets/loaders/DefaultLoader";

import useFetchLogs from '../../../hooks/admin/useFetchLogs';
import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

function ActivityAdmin() {
    useAuthAdmin();
    useSetPageTitle('Promos Juntos+ | Admin');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { logs } = useFetchLogs(setLoading, setError);

    return(
        <div className="admin-page-container">
            <div className="admin-page-title-container">
                <p className="admin-page-title">Histórico de atividades</p>
                { loading && !error ? <DefaultLoader /> : false }
                { !loading && error ? <p className="admin-page-error-fetching">Ocorreu um erro, por favor recarregue a página</p> : false }
            </div>
            <LogTable logs={logs} />
        </div>
    )
}

export default ActivityAdmin;