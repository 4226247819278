import { useEffect } from 'react';
import axios from 'axios';

const useFetchVouchers = (setNumbers) => {

    useEffect(() => {
        axios
            .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/client/meus-vouchers`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                  },
            })
            .then((response) => {
                setNumbers(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setNumbers]);

}

export default useFetchVouchers;