import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('adminAccessToken')) {
      navigate('/admin');
    }
  }, [navigate]);

};

export default useAuthAdmin;
