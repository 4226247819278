import React, { useState } from 'react';
import './styles.css';
import closedEye from '../../assets/icons/hide.png';
import openedEye from '../../assets/icons/show.png';

function HideTextInput({
  label, placeholder, value, setValue, warningMessage, disabled, forgot, rubik, forgotNav, black, ref,
}) {
  const [hidden, setHidden] = useState(true);

  const togglePasswordVisibility = () => setHidden(!hidden);

  return (
    <div className={`text-input-component ${rubik ? 'rubik' : ''}`} ref={ref}>
      <div className="label-container">
        <p
          className="label"
          style={{ color: black ? '#202020' : '#FFFFFF', fontSize: rubik ? '16px' : '18px' }}
        >
          {label}
        </p>
        {forgot && (
          <p className="forgot" onClick={forgotNav} role="button" tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && forgotNav()}>
            Esqueceu sua senha?
          </p>
        )}
      </div>
      <div className="input-container">
        <input
          type={hidden ? 'password' : 'text'}
          className={`input ${warningMessage && !black ? 'invalid' : ''} ${!warningMessage && value ? 'valid' : ''} ${warningMessage && black ? 'invalid-black' : ''}`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          style={{ fontSize: rubik ? '18px' : '21px' }}
        />
        <img
          className="eye-icon"
          alt="eye-visibility"
          src={hidden ? closedEye : openedEye}
          onClick={togglePasswordVisibility}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') togglePasswordVisibility();
          }}
          role="button"
          tabIndex={0}
        />
      </div>
      <p className="warning-message" style={{ color: black ? '#FF5F5F' : '#FBE1E0' }}>{warningMessage}</p>
    </div>
  );
}

export default HideTextInput;
