const validateName = (name, setName, setNameWarning) => {
    if (!name.length) {
        setName('');
        setNameWarning('');
        return;
    }
    setName(name);
    if (name.length > 50) {
        setNameWarning("Insira um nome menor");
        return;
    }
    if (!name.length) {
        setNameWarning('');
        setName('');
        return;
    }
    setNameWarning('');
}

export default validateName;