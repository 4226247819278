import React from 'react';

import './styles.css';

function Status({ status }) {
    if (status === 'Inválido') {
        return(
            <div className=" status status-expirado">
                <p>INVÁLIDO</p>
            </div>
        )
    } else if (status === 'aguardando') {
        return(
            <div className='status status-aguardando'>
                <p>AGUARDANDO</p>
            </div>
        )
    } else if (status === 'analise_endereco') {
        return(
            <div className='status status-analise'>
                <p>EM ANÁLISE</p>
            </div>
        )
    } else if (status === 'envio_pendente') {
        return(
            <div className='status status-pendente'>
                <p>AGUARD. ENVIO</p>
            </div>
        )
    } else if (status === 'sorteado') {
        return(
            <div className='status status-sorteado'>
                <p>{status.toUpperCase()}</p>
            </div>
        )
    } else if (status === 'enviado') {
        return(
            <div className='status status-sorteado'>
                <p>{status.toUpperCase()}</p>
            </div>
        )
    } else if (status === 'Resgatar') {
        return(
            <div className='status status-resgatar'>
                <p>{status.toUpperCase()}</p>
            </div>
        )
    } else {
        return(
            <div>

            </div>
        )
    }
}

export default Status;