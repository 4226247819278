import React, { useState } from "react";

import RadioButtons from "../../../components/Admin/RadioButtons/RadioButtons";

import tryExportingRegistered from "./exports/registereds";

import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import DefaultLoader from "../../../assets/loaders/DefaultLoader";

import './styles.css';

function RegisteredAdmin() {
    useAuthAdmin();
    useSetPageTitle('Promos Juntos+ | Admin');
    
    const [option, setOption] = useState(0);
    const radioButtonsList = ['Baixar clientes cadastrados na promoção', 'Baixar clientes elegíveis que não se cadastraram'];

    const [loading, setLoading] = useState(false);

    return(
        <>
            <div className="admin-page-container">
            <div className="admin-page-title-container">
                <p className="admin-page-title">Exportar clientes cadastrados</p>
            </div>
            <RadioButtons 
                radioButtonsList={radioButtonsList}
                option={option}
                setOption={setOption}
            />
            <div className="admin-page-button-container">
                <button className="admin-page-primary-button" onClick={() => tryExportingRegistered(option, setLoading)}>Baixar</button>
                { loading ? <DefaultLoader /> : false }
            </div>
            </div>
        </>
    )
}

export default RegisteredAdmin;