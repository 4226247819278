const validateDefault = (field, setField, setFieldWarning) => {
    if (!field.length) {
        setField('');
        setFieldWarning('Este campo é obrigatório');
        return;
    }
    setField(field);
    setFieldWarning('');
}

export default validateDefault;