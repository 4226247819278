const validateConfirmPassword = (confirmPassword, setConfirmPassword, setConfirmPasswordWarning, password) => {
    setConfirmPassword(confirmPassword);
    if (!confirmPassword.length) {
        setConfirmPasswordWarning('');
        return;
    }
    if (confirmPassword !== password) {
        setConfirmPasswordWarning("As senhas devem ser idênticas");
    } else setConfirmPasswordWarning('');
}

export default validateConfirmPassword;