import React, { useState } from "react";

import './styles.css';

import FileInput from "../../../components/Admin/FileInput/FileInput";
import DownloadModel from "../../../components/Admin/DownloadModel/DownloadModel";
import RadioButtons from "../../../components/Admin/RadioButtons/RadioButtons";

import tryExportingElegibles from "./exports/eligibles";
import tryImportingElegibles from "./imports/elegibles";

import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useCheckRoleActions from "../../../hooks/admin/useCheckRoleActions";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import DefaultLoader from "../../../assets/loaders/DefaultLoader";
import LinearLoader from "../../../assets/loaders/LinearLoader";

function ElegiblesAdmin() {
    useAuthAdmin();
    useSetPageTitle('Promos Juntos+ | Admin');

    const [option, setOption] = useState(0);
    const [radioButtonsList, setRadioButtonsList] = useState(['Atualizar clientes elegíveis com o arquivo enviado', 'Substituir base de elegíveis pelo arquivo enviado*']);

    useCheckRoleActions(setRadioButtonsList);

    const [file, setFile] = useState(null);
    const [loadingImport, setLoadingImport] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [errorImport, setErrorImport] = useState('');
    const [errorExport, setErrorExport] = useState('');
    const [successImport, setSuccessImport] = useState('');

    const downloadModel = () => {
        const link = document.createElement('a');
        link.href = "/models/modelo-elegiveis.csv";
        link.download = "modelo-elegiveis.csv";
        link.click();
    };
    
    return(
        <>
            <div className="admin-page-container">
                <div className="admin-page-title-container">
                    <p className="admin-page-title">Importar elegíveis</p>
                    {
                        localStorage.getItem('role') !== '1' ?
                        <DownloadModel label="Baixe o modelo de planilha" onClick={downloadModel} />
                        : false
                    }
                </div>
                {
                    localStorage.getItem('role') !== '1' ?
                    <>
                        <FileInput setValue={setFile} />
                        <RadioButtons 
                            radioButtonsList={radioButtonsList}
                            option={option}
                            setOption={setOption} 
                        />
                        <div className="admin-page-button-container">
                            <button className="admin-page-primary-button" onClick={() => tryImportingElegibles(file, setLoadingImport, setErrorImport, setSuccessImport)}>Enviar</button><br />
                            { loadingImport ? <DefaultLoader /> : false }
                        </div>
                        <p className="admin-page-error-import">{errorImport}</p>
                        <p className="admin-page-success-import">{successImport}</p>
                    </>
                    : false
                }
                <div className="admin-page-export-container">
                    <button className="admin-page-secundary-button" onClick={() => tryExportingElegibles(setLoadingExport, setErrorExport)}>Baixar CPFs participantes <span className="button-bold">atuais</span></button>
                    { loadingExport ? <LinearLoader /> : false }
                </div>
                <p className="admin-page-error-import">{errorExport}</p>
            </div>
        </>
    )
}

export default ElegiblesAdmin;